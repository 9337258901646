import React, { Component } from 'react';
import HeaderTwo from './components/HeaderTwo';
import HeroSlider from './components/HeroSlider';
import ServiceBox from './components/ServiceBox';
import AboutUsTwo from './components/AboutUsTwo';
import CourseSlider from './components/CourseSlider';
import FreeCourse from './components/FreeCourse';
import TestimonialSlider from './components/TestimonialSlider';
import FooterTwo from './components/FooterTwo';

export default class HomeTwo extends Component {
    render() {
        return (
            <div className="main-wrapper">

                {/* Header 2 */}
                <HeaderTwo />
                <HeroSlider/>
                {/* Hero Image 
                <HeroImage />*/}

                  {/* Course Slider */}
                  <CourseSlider />

                {/* Service Box */}
                <ServiceBox />

                {/* About Us 2 */}
                <AboutUsTwo />

                {/* Testimonial Slider */}
                <TestimonialSlider />
                {/* Counter Area 
                <NumberCounter />*/}

                {/* Free Course Area */}
                <FreeCourse />

                {/* Team Slider 
                <TeamSlider />*/}

                

                {/* Blog Area */}
              {/*  <HomeBlog />*/}

                {/* Image Gallery Area */}
                {/*<ImageGallery />*/}

                {/* Footer 2 */}
                <FooterTwo />

            </div>
        )
    }
}
