import axios from "axios";

import * as c from "../utils/constants";

// ================== functions ============ //

export async function createGroup(data) {
  try {
    let res = await axios.post(c.CREATE_GROUP, data);
    return res.data;
  } catch (e) {
    // alert(e);
    console.log(e);
  }
}
export async function registerUser(data) {
  try {
    let res = await axios.post(c.REGISTER, data);
    return res.data;
  } catch (e) {
    // alert(e);
    console.log(e);
  }
}

export async function createSubGroup(data) {
  try {
    let res = await axios.post(c.CREATE_SUB_GROUP, data);
    return res.data;
  } catch (e) {
    // alert(e);
    console.log(e);
  }
}

export async function allGroups() {
  try {
    let res = await axios.get(c.ALL_GROUPS);
    return res.data;
  } catch (e) {
    // alert(e);
    console.log(e);
  }
}

export async function getGroupDetailsByGroupId(id) {
  try {
    let res = await axios.get(c.GET_GROUP_DETAILS_BY_GROUP_ID + id);
    return res.data;
  } catch (e) {
    // alert(e);
    console.log(e);
  }
}

export async function getAllCourses() {
  try {
    let res = await axios.get(c.GET_ALL_COURSES);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getCourseDetails(id) {
  let courseId = id.toString();
  try {
    let res = await axios.get(c.GET_COURSE_DETAILS + courseId);
    return res.data;
  } catch (e) {
    // alert(e);
    console.log(e);
  }
}

export async function saveCourseReview(data) {
  let POST_REVIEW_DATA = data;
  try {
    let res = await axios.post(c.SAVE_REVIEW, POST_REVIEW_DATA);
    return res;
  } catch (error) {}
}
