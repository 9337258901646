import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';

import FooterTwo from '../../components/FooterTwo';
import { Styles } from '../contact/styles/contact.js';

function InstructorRegistration() {
    useEffect(() => {
        const form = document.getElementById("form_contact");
        const name = document.getElementById("contact_name");
        const email = document.getElementById("contact_email");
        const subject = document.getElementById("contact_subject");
        const message = document.getElementById("contact_message");

        form.addEventListener("submit", formSubmit);

        function formSubmit(e) {
            let success=true;
            e.preventDefault();

            const nameValue = name.value.trim();
            const emailValue = email.value.trim();
            const subjectValue = subject.value.trim();
            const messageValue = message.value.trim();

            if (nameValue === "") {
                setError(name, "Name can't be blank");
                success=false;
            } else {
                setSuccess(name);
            }

            if (emailValue === "") {
                setError(email, "Mobile can't be blank");
                success=false;
            }else {
                setSuccess(email);
            }

            if (subjectValue === "") {
                setError(subject, "Course can't be blank");
                success=false;
            } else {
                setSuccess(subject);
            }

            if (messageValue === "") {
                setError(message, "Message can't be blank");
                success=false;
            } else {
                setSuccess(message);
            }
            if(success)
            {
                alert(" Thank you for your application. We will get back to you soon!!!")
            }
        }

        function setError(input, message) {
            const formControl = input.parentElement;
            const errorMsg = formControl.querySelector(".contact_input-msg");
            formControl.className = "form-control text-left error";
            errorMsg.innerText = message;
        }

        function setSuccess(input) {
            const formControl = input.parentElement;
            formControl.className = "form-control success";
        }

        
    });

    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper contact-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="Become an Instructor" />

                {/* Contact Area */}
                <section className="contact-area">
                    <Container>
                        <Row>
                            <Col md="6">
                                <div className="contact-box-title">
                                    <h4>Start your journey</h4>
                                </div>
                                <div className="contact-icon-box d-flex">
                                    
                                    <div className="box-content">
                                        <h5>Who can teach?</h5>
                                        <p>Anyone who has an in-depth working knowledge of the particular domain and is very passionate about teaching and sharing his/her expert knowledge with students and professionals can teach at Skillbhoomi. Good oral communication skills are mandatory.</p>
                                    </div>
                                </div>
                                <div className="contact-icon-box d-flex">
                                    
                                    <div className="box-content">
                                        <h5>Advantage Skillbhoomi</h5>
                                        <p>Apart from working with a very young and talented team, you will also get the revenue-sharing opportunity at the fastest growing online training company in India! You will be provided with a great platform to showcase your practical knowledge and skills that you have acquired over a period of time. What more, you can conduct your training from any part of the world!</p>
                                    </div>
                                </div>
                                <div className="contact-icon-box d-flex">
                                   
                                    <div className="box-content">
                                        <h5>The Process</h5>
                                        <p>First thing first! You need to fill this form. If you are shortlisted and finally selected, you will undergo a training based on our Learning Methodology. You will also get the opportunity to co-create the content with us to make ‘learning’ a very rich and fruitful experience for the learn.</p>
                                    </div>
                                </div>
                                
                            </Col>
                            <Col md="6">
                                <div className="contact-form">
                                    <div className="form-title">
                                        <h4>Get In Touch</h4>
                                    </div>
                                    <div className="form-box">
                                        <form id="form_contact" className="form">
                                            <Row>
                                                <Col md="6">
                                                    <p className="form-control">
                                                        <input type="text" placeholder="Full Name" id="contact_name" />
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>
                                                <Col md="6">
                                                    <p className="form-control">
                                                        <input type="text" placeholder="Mobile" id="contact_email" />
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>
                                                <Col md="12">
                                                    <p className="form-control">
                                                        <input type="text" placeholder="What you want to teach?" id="contact_subject" />
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>
                                                <Col md="12">
                                                    <p className="form-control">
                                                        <textarea name="message" id="contact_message" placeholder="Tell us more about you"></textarea>
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>
                                                <Col md="12">
                                                    <button>Apply Now</button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    
                </section>

                {/* Footer 2 */}
                <FooterTwo />

            </div>
        </Styles>
    )
}

export default InstructorRegistration