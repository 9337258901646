import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { saveCourseReview } from "../../../api/AuthServices.js";
import { Styles1 } from "../styles/reviewForm.js";

function ReviewForm({ courseId }) {
  const [comment, setComment] = useState("");
  const [emailId, setEmailId] = useState("");
  const [userName, setUserName] = useState("");
  const [count, setCount] = useState(0);
  // const [formData , setFormData] = useState({});
  const [c, setC] = useState(0);

  // const form = document.getElementById("form6");
  const desc = document.getElementById("desc6");
  const name = document.getElementById("name6");
  const email = document.getElementById("email6");

  // form.addEventListener("submit", formSubmit);

  function formSubmit(e) {
    e.preventDefault();

    const descValue = desc.value.trim();
    const nameValue = name.value.trim();
    const emailValue = email.value.trim();

    if (descValue === "") {
      setError(desc, "Comment can't be blank");
    } else {
      setC(c + 1);
      setSuccess(desc);
      
    }

    if (nameValue === "") {
      setError(name, "Name can't be blank");
    } else {
       setC(c + 1);
      setSuccess(name);
     
    }

    if (emailValue === "") {
      setError(email, "Email can't be blank");
    } else if (!isEmail(emailValue)) {
      setError(email, "Not a valid email");
    } else {
      setC(c + 1);
      setSuccess(email);
    }
    if (userName !== "" && emailId !== "" && comment!=="") {
      let data = {
        courseId: courseId,
        reviewerName: userName,
        reviewComment: comment,
        reviewRating: count,
        emailId: emailId,
      };
      saveCourseReview(data).then((res) => {
        window.location.reload();
      });
    }
  }

  function setError(input, message) {
    const formControl = input.parentElement;
    const errorMsg = formControl.querySelector(".input-msg6");
    formControl.className = "form-control error";
    errorMsg.innerText = message;
  }

  function setSuccess(input) {
    const formControl = input.parentElement;
    formControl.className = "form-control success";
  }

  function isEmail(email) {
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
  }

  //   console.log("email--" + emailId);
  //   console.log("name--" + userName);
  //   console.log("text--" + comment);
  // console.log("count--" + count);

  return (
    <Styles1>
      <div id="form6" className="form review-comment-form">
        <Row>
          <Col md="12">
            <div className="star-rating">
              <input
                type="radio"
                name="rate"
                id="rate-5"
                onChange={() => setCount(5)}
              />
              <label htmlFor="rate-5" className="las la-star"></label>
              <input
                type="radio"
                name="rate"
                id="rate-4"
                onChange={() => setCount(2 + 2)}
              />
              <label htmlFor="rate-4" className="las la-star"></label>
              <input
                type="radio"
                name="rate"
                id="rate-3"
                onChange={() => setCount(3)}
              />
              <label htmlFor="rate-3" className="las la-star"></label>
              <input
                type="radio"
                name="rate"
                id="rate-2"
                onChange={() => setCount(4 - 2)}
              />
              <label htmlFor="rate-2" className="las la-star"></label>
              <input
                type="radio"
                name="rate"
                id="rate-1"
                onChange={() => setCount(1)}
              />
              <label htmlFor="rate-1" className="las la-star"></label>
            </div>
          </Col>
          <Col md="12">
            <p className="form-control">
              <textarea
                name="comment"
                id="desc6"
                placeholder="Enter your review"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
              <span className="input-msg6"></span>
            </p>
          </Col>
          <Col md="6">
            <p className="form-control">
              <input
                type="name"
                placeholder="Enter your name"
                id="name6"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
              <span className="input-msg6"></span>
            </p>
          </Col>
          <Col md="6">
            <p className="form-control">
              <input
                type="email"
                placeholder="Enter your email"
                id="email6"
                value={emailId}
                onChange={(e) => setEmailId(e.target.value)}
              />
              <span className="input-msg6"></span>
            </p>
          </Col>
          <Col md="12">
            <button type="button" onClick={(e) => formSubmit(e)}>
              Submit Review
            </button>
          </Col>
        </Row>
      </div>
    </Styles1>
  );
}

export default ReviewForm;
