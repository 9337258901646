import React, { useState } from "react";
import Datas from "../data/corporate-training/corporate-training.json";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/corporateTraining.js";

const CorporateTraining = () => {
  const [userName, setUserName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [trainingNeed, setTrainingNeed] = useState("");
  const [emailID, setEmailID] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [query, setQuery] = useState("");

  const initialValue = {
    coursecus: false,
    learnanalytics: false,
    cloudlab: false,
    support: false,
    certification: false,
    projects: false,
  };
  const [data, setData] = useState(initialValue);

  // const form = document.getElementById("form");
  const list = document.getElementById("list");
  const company = document.getElementById("companyName");
  const name = document.getElementById("name");
  const email = document.getElementById("email");
  // const training = document.getElementById("training");
  const phone = document.getElementById("phone");
  const queryval = document.getElementById("query");
  // form.addEventListener("submit", formSubmit);
  // }
  console.log("companyname==" + companyName);

  function formSubmit(e) {
    e.preventDefault();

    // const descValue = desc.value.trim();
    // const nameValue = name.value;
    // const emailValue = email.value;
    // const phoneValue = phone.value;
    // const companyValue = company.value;
    // const queryValue = queryval.value;

    if (userName === "") {
      setError(name, "Name can't be blank");
    } else {
      setSuccess(name);
    }
    if (phoneNumber === "") {
      setError(phone, "Phone Number can't be blank");
    } else {
      setSuccess(phone);
    }
    if (companyName === "") {
      setError(company, "Company Name can't be blank");
    } else {
      setSuccess(company);
    }
    if (query === "") {
      setError(queryval, "Please enter your query");
    } else {
      setSuccess(queryval);
    }
    if (trainingNeed === "") {
      setError(list, "Please select any option");
    } else {
      setSuccess(list);
    }
    if (emailID === "") {
      setError(email, "Email can't be blank");
    } else if (!isEmail(emailID)) {
      setError(email, "Not a valid email");
    } else {
      setSuccess(email);
    }

    // console.log("companyname inside==" + companyName);
    if (list == null) {
      console.log("hurray");
    }
  }
  function handleChange() {
    setTrainingNeed(document.getElementById("list").value);
  }

  function setError(input, message) {
    const formControl = input.parentElement;
    const errorMsg = formControl.querySelector(".input-msg6");
    formControl.className = "form-controls error";
    errorMsg.innerText = message;
  }

  function setSuccess(input) {
    const formControl = input.parentElement;
    formControl.className = "form-controls success";
  }

  function isEmail(email) {
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
  }

  function hideShowContext(varname) {
    switch (varname) {
      case "coursecus":
        setData({
          coursecus: !coursecus,
          learnanalytics: false,
          cloudlab: false,
          support: false,
          certification: false,
          projects: false,
        });
        break;
      case "learnanalytics":
        setData({
          coursecus: false,
          learnanalytics: !learnanalytics,
          cloudlab: false,
          support: false,
          certification: false,
          projects: false,
        });

        break;
      case "cloudlab":
        setData({
          coursecus: false,
          learnanalytics: false,
          cloudlab: !cloudlab,
          support: false,
          certification: false,
          projects: false,
        });

        break;
      case "support":
        setData({
          coursecus: false,
          learnanalytics: false,
          cloudlab: false,
          support: !support,
          certification: false,
          projects: false,
        });

        break;
      case "certification":
        setData({
          coursecus: false,
          learnanalytics: false,
          cloudlab: false,
          support: false,
          certification: !certification,
          projects: false,
        });

        break;
      case "projects":
        setData({
          coursecus: false,
          learnanalytics: false,
          cloudlab: false,
          support: false,
          certification: false,
          projects: !projects,
        });

        break;
      default:
        return;
    }
  }

  const {
    coursecus,
    learnanalytics,
    cloudlab,
    support,
    certification,
    projects,
  } = data;

  return (
    <Styles>
      <section className="corporate-training">
        <Container>
          <Row>
            <Col className="main-img-container">
              <div className="about-image">
                {/* <img width="1100" height="300" src={process.env.PUBLIC_URL + `/assets/images/${Datas.mainImage}`} className="main-img" alt="skillbhoomi" /> */}
                <img
                  src={
                    process.env.PUBLIC_URL + `/assets/images/${Datas.mainImage}`
                  }
                  className="main-img"
                  alt="skillbhoomi"
                />
              </div>
              <div className="hiretalent-container">
                <Container>
                  <Row>
                    <Col>
                      <div className="hiretalent-btn">
                        <div className="slider-btn">
                          <Link
                            className="slider-btn1"
                            to={process.env.PUBLIC_URL}
                          >
                            HIRE TALENT NOW
                          </Link>
                        </div>
                      </div>
                      <div className="tc-container">
                        <div className="hiretalent-tc">*T&C Apply</div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <div className="btn-list">
          <div className="course-icons">
            <button className="course-btn-1">
              <span>
                <div>
                  <img
                    className="img img-fluid img-icon"
                    src={process.env.PUBLIC_URL + "/assets/images/AI logo.png"}
                    alt="skillbhoomi"
                  />
                </div>
              </span>
            </button>
            <button className="course-btn-2">
              <span>
                <div>
                  <img
                    className="img img-fluid img-icon"
                    src={process.env.PUBLIC_URL + "/assets/images/ML logo.png"}
                    alt="skillbhoomi"
                  />
                </div>
              </span>
            </button>
            <button className="course-btn-3">
              <span>
                <div>
                  <img
                    className="img img-fluid img-icon"
                    src={
                      process.env.PUBLIC_URL + "/assets/images/Blockchain.png"
                    }
                    alt="skillbhoomi"
                  />
                </div>
              </span>
            </button>
            <button className="course-btn-4">
              <span>
                <div>
                  <img
                    className="img img-fluid img-icon"
                    src={process.env.PUBLIC_URL + "/assets/images/DevOps.png"}
                    alt="skillbhoomi"
                  />
                </div>
              </span>
            </button>
            <button className="course-btn-5">
              <span>
                <div>
                  <img
                    className="img img-fluid img-icon"
                    src={
                      process.env.PUBLIC_URL + "/assets/images/Full stack.png"
                    }
                    alt="skillbhoomi"
                  />
                </div>
              </span>
            </button>
            <button className="course-btn-6">
              <span>
                <div>
                  <img
                    className="img img-fluid img-icon mobility"
                    src={process.env.PUBLIC_URL + "/assets/images/Mobility.png"}
                    alt="skillbhoomi"
                  />
                  <div className="mobility-text">Mobility</div>
                </div>
              </span>
            </button>
          </div>
        </div>
      </section>

      <section className="misc-content  container py-2 mt-2">
        <div className="div-sub">
          <div className="d-flex flex-column align-items-center px-4 py-4 ">
            <span className="txt-color">100%</span>
            <span className="sub-txt">Commited &amp; Job Ready</span>
          </div>
          <div className="d-flex flex-column align-items-center px-4 py-4">
            <span className="txt-color">1st</span>
            <span className="sub-txt">Day Productive</span>
          </div>
        </div>
        <div className="div-sub">
          <div className="d-flex flex-column align-items-center  py-4">
            <div className="d-flex flex-column align-items-center">
              <span className="txt-color">1100+</span>
              <span className="sub-txt">Hrs. Coding Experience</span>
            </div>
          </div>
          <div className="d-flex flex-column align-items-center  py-4">
            <div className="d-flex flex-column align-items-center">
              <span className="txt-color">2.3X</span>
              <span className="sub-txt">Return On Investment</span>
            </div>
          </div>
        </div>
      </section>

      <article className="corpfeatures">
        <h2 className="title">SkillBhoomi Corporate Training Features</h2>
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 frs-main">
            <div className="col-sm-6 col-xs-12 corpfeatleft">
              <a
                className="featcardmain"
                onClick={() => hideShowContext("coursecus")}
              >
                <div className="header">
                  <div className="imgcont">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/cource-custom.svg"
                      }
                      alt="Coure Custom - Corporate Training"
                      width="100%"
                      height="auto"
                    />
                  </div>
                  <h3 className="featuretitle">Course Customization</h3>
                </div>
                <p className="featdesc">
                  Flexible Engagement Model Tailor-Made for Your Needs
                </p>
                <div
                  className={
                    coursecus
                      ? "leftfeatcontentsec coursecus show"
                      : "leftfeatcontentsec coursecus"
                  }
                >
                  <span className="visible-xs closemodal">
                    <i className="icon-cross"></i>
                  </span>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/course_customization.jpg"
                    }
                    alt="Course Features"
                    width="100%"
                    height="auto"
                  />
                  <ul className="coursefeatlist">
                    <li>
                      <span>Content Customization as per Project</span>
                    </li>
                    <li>
                      <span>
                        Flexibility to Choose Location, Mode, and Dates
                      </span>
                    </li>
                    <li>
                      <span>
                        Technology Awareness Session for Senior Management
                      </span>
                    </li>
                  </ul>
                </div>
              </a>
              <a
                className="featcardmain"
                onClick={() => hideShowContext("learnanalytics")}
              >
                <div className="header">
                  <div className="imgcont">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/learning-analytics.svg"
                      }
                      alt="Learning Analytics - Corporate Training"
                      width="100%"
                      height="auto"
                    />
                  </div>
                  <h3 className="featuretitle">Learning Analytics</h3>
                </div>
                <p className="featdesc">
                  Customized Dashboard to Visualize Training Progress
                </p>
                <div
                  className={
                    learnanalytics
                      ? "leftfeatcontentsec learnanalytics show"
                      : "leftfeatcontentsec learnanalytics"
                  }
                >
                  <span className="visible-xs closemodal">
                    <i className="icon-cross"></i>
                  </span>
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/analytic.jpg"}
                    alt="Course Features"
                    width="100%"
                    height="auto"
                  />
                  <ul className="coursefeatlist">
                    <li>
                      <span>Tracking of Learners’ Comparative Progress</span>
                    </li>
                    <li>
                      <span>Insights into Learning Effectiveness</span>
                    </li>
                    <li>
                      <span>Auto-Generated Reporting to Management</span>
                    </li>
                  </ul>
                </div>
              </a>
              <a
                className="featcardmain"
                onClick={() => hideShowContext("cloudlab")}
              >
                <div className="header">
                  <div className="imgcont">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/cloud-lab.svg"
                      }
                      alt="Cloud Lab - Corporate Training"
                      width="100%"
                      height="auto"
                    />
                  </div>
                  <h3 className="featuretitle">Cloud Labs</h3>
                </div>
                <p className="featdesc">
                  Hands-On Experience on SkillBhoomi Cloud Lab, Pre-Configured
                  to Get Started Immediately
                </p>
                <div
                  className={
                    cloudlab
                      ? "leftfeatcontentsec cloudlab show"
                      : "leftfeatcontentsec cloudlab"
                  }
                >
                  <span className="visible-xs closemodal">
                    <i className="icon-cross"></i>
                  </span>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/cloud_lab.jpg"
                    }
                    alt="Course Features"
                    width="100%"
                    height="auto"
                  />
                  <ul className="coursefeatlist">
                    <li>
                      <span>Pre-Configured Ready to Use Environment</span>
                    </li>
                    <li>
                      <span>
                        Access CloudLab from anywhere through SkillBhoomi LMS
                      </span>
                    </li>
                    <li>
                      <span>
                        Real time environment with real world case studies
                      </span>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
            <div className="col-sm-6 col-xs-12 corpfeatright">
              <a
                className="featcardmain"
                onClick={() => hideShowContext("support")}
              >
                <div className="header">
                  <div className="imgcont">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/24x7.svg"}
                      alt="24/7 Suport - Corporate Training"
                      width="100%"
                      height="auto"
                    />
                  </div>
                  <h3 className="featuretitle">24x7 Support</h3>
                </div>
                <p className="featdesc">
                  Round-the-Clock Support by In-House Subject-Matter Experts
                </p>
                <div
                  className={
                    support
                      ? "rightfeatcontentsec support show"
                      : "rightfeatcontentsec support"
                  }
                >
                  <span className="visible-xs closemodal">
                    <i className="icon-cross"></i>
                  </span>
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/support.jpg"}
                    alt="Course Features"
                    width="100%"
                    height="auto"
                  />
                  <ul className="coursefeatlist">
                    <li>
                      <span>Ticket Resolution Within 24 Hours</span>
                    </li>
                    <li>
                      <span>
                        Support Available Even After Course Completion
                      </span>
                    </li>
                    <li>
                      <span>
                        Additional Batch/Course Specific Communities for Q&amp;A
                      </span>
                    </li>
                  </ul>
                </div>
              </a>
              <a
                className="featcardmain"
                onClick={() => this.hideShowContext("certification")}
              >
                <div className="header">
                  <div className="imgcont">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/certification.svg"
                      }
                      alt="Certification - Corporate Training"
                      width="100%"
                      height="auto"
                    />
                  </div>
                  <h3 className="featuretitle">Certifications</h3>
                </div>
                <p className="featdesc">
                  Industry Recognized Certification to Add Value to Your
                  Workforce
                </p>
                <div
                  className={
                    certification
                      ? "rightfeatcontentsec certification show"
                      : "rightfeatcontentsec certification"
                  }
                >
                  <span className="visible-xs closemodal">
                    <i className="icon-cross"></i>
                  </span>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/certification-1.jpg"
                    }
                    alt="Course Features"
                    width="100%"
                    height="auto"
                  />
                  <ul className="coursefeatlist">
                    <li>
                      <span>Strict Evaluation Process</span>
                    </li>
                    <li>
                      <span>Widely recognized certificate</span>
                    </li>
                    <li>
                      <span>Verifiable by Employer</span>
                    </li>
                  </ul>
                </div>
              </a>
              <a
                className="featcardmain"
                onClick={() => this.hideShowContext("projects")}
              >
                <div className="header">
                  <div className="imgcont">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/projects.svg"
                      }
                      alt="Projects - Corporate Training"
                      width="100%"
                      height="auto"
                    />
                  </div>
                  <h3 className="featuretitle">Projects</h3>
                </div>
                <p className="featdesc">
                  Right Mix of Theoretical and Practical Training for Real World
                  Industry Problems
                </p>
                <div
                  className={
                    projects
                      ? "rightfeatcontentsec projects show"
                      : "rightfeatcontentsec projects"
                  }
                >
                  <span className="visible-xs closemodal">
                    <i className="icon-cross"></i>
                  </span>
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/project.jpg"}
                    alt="Course Feature"
                    width="100%"
                    height="auto"
                  />
                  <ul className="coursefeatlist">
                    <li>
                      <span>
                        Projects in Retail, Healthcare, Pharma, Aviation etc.
                      </span>
                    </li>
                    <li>
                      <span>Learners Choose Project as per Needs</span>
                    </li>
                    <li>
                      <span>Projects Evaluated for Certification</span>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
        </div>
      </article>

      <div className="shareformfeed">
        <div className="container">
          <div className="requestinfosec corp-form-wrapper">
            <h2>Talk to our training advisor</h2>
            <span className="greenunderline"></span>
            {/* <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding bot-form-post"> */}
            <form id="form" className="form6" onSubmit={formSubmit}>
              <div className="formwrap">
                <Row>
                  <Col md="12">
                    <label>Name*</label>
                    <p className="form-controls">
                      <input
                        type="text"
                        id="name"
                        style={{ width: "100%" }}
                        placeholder="Name"
                        value={userName === null ? "" : userName}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                      <span className="input-msg6"></span>
                    </p>
                  </Col>

                  <Col md="6">
                    <label>Company Name*</label>
                    <p className="form-controls">
                      <input
                        type="text"
                        id="companyName"
                        style={{ width: "100%" }}
                        placeholder="Enter Company Name"
                        value={companyName === null ? "" : companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                      <span className="input-msg6"></span>
                    </p>
                  </Col>
                  <Col md="6">
                    <label>Training Need*</label>
                    <p className="form-controls">
                      <select id="list" onChange={handleChange}>
                        <option value="Select an Option" diabled="true" hidden>
                          Select an Option
                        </option>
                        <option value="individual">For Myself</option>
                        <option value="team">For Team</option>
                        <option value="corporate">For Corporate</option>
                      </select>
                      <span className="input-msg6"></span>
                    </p>
                  </Col>
                  <Col md="6">
                    <label>Email ID*</label>
                    <p className="form-controls">
                      <input
                        type="text"
                        id="email"
                        style={{ width: "100%" }}
                        placeholder="Email ID"
                        value={emailID === null ? "" : emailID}
                        onChange={(e) => setEmailID(e.target.value)}
                      />
                      <span className="input-msg6"></span>
                    </p>
                  </Col>
                  <Col md="6">
                    <label>Phone Number*</label>
                    <p className="form-controls">
                      <input
                        type="text"
                        id="phone"
                        placeholder="Phone Number"
                        value={phoneNumber === null ? "" : phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                      <span className="input-msg6"></span>
                    </p>
                  </Col>
                  <Col md="12">
                    <label>Query*</label>
                    <p className="form-controls">
                      <textarea
                        type="text"
                        id="query"
                        placeholder="Enter your Query"
                        value={query === null ? "" : query}
                        onChange={(e) => setQuery(e.target.value)}
                      />
                      <span className="input-msg6"></span>
                    </p>
                  </Col>
                  <Col md="12">
                    <div style={{ display: "flex" }}>
                      <button type="submit">Submit</button>
                    </div>
                  </Col>
                </Row>
              </div>
            </form>
            {/* </div> */}
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default CorporateTraining;
