import React, { Component } from 'react';
import Datas from '../../data/event/details.json';
import { Link,useHistory,useParams,useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Timer from 'react-compound-timer';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import PopularCourse from './../courses/components/PopularCourse';
import CourseTag from './../courses/components/CourseTag';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/eventDetails.js';

class EventDetails extends Component {
    
     
      getDifferenceInSeconds( dateVar){
        const date1 = new Date(dateVar);
        const date2 = new Date(); 
        const diffInMs = Math.abs(date2 - date1);
        console.log("diffInMs    "+date1)
        return diffInMs ;
      }
     handleSubmit = (employee) => {
        
        this.props.history.push('/eventRegister');
      
    }
    render() {

        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper event-details-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Event Details" />

                    {/* Event Details Area */}
                    <section className="event-details-area">
                        <Container>
                            <Row>
                                <Col lg="9" md="8" sm="12">
                                    <div className="event-details-content">
                                        <div className="heading">
                                            <h4>Join the Frontend Masters Web Development Bootcamp...Free!</h4>
                                        </div>
                                        <div className="event-icon">
                                            <ul className="list-unstyled list-inline">
                                                <li className="list-inline-item"><i className="las la-calendar"></i> 7-9 Oct, 2021</li>
                                                <li className="list-inline-item"><i className="las la-clock"></i> 5:00PM-8:00PM</li>
                                                <li className="list-inline-item"><i className="las la-map-marker"></i> Zoom Meeting</li>
                                                <li className="list-inline-item"><i className="las la-copy"></i> Software Engineering</li>
                                            </ul>
                                        </div>
                                        <div className="event-details-banner">
                                            <img src={process.env.PUBLIC_URL + `/assets/images/eventposter.jpg`} alt="" className="img-fluid" />
                                        </div>
                                        <div className="event-details-overview">
                                            <h5>Bootcamp Overview</h5>
                                            <p>SkillBhoomi presents to you a specially curated Bootcamp Series encompassing all traits you need to excel in your career.
                                            The Frontend Masters Bootcamp is a complete crash course, and everything you need to get up and running with HTML, CSS and JavaScript. By completing the coursework contained in the Bootcamp, along with practice, you will be well on your way into the world of web development!.
                                            All of our courses are taught by industry leading experts, people actively shaping the craft of web development. Get a deeper understanding of Client Side Programming or in other words the front end development by developing highly responsive web pages across languages.</p>
                                            <ul className="list-unstyled">
                                                <li><i className="fa fa-check"></i> Understand how websites work and how HTML, CSS and JavaScript contribute.</li>
                                                <li><i className="fa fa-check"></i> Learn HTML tags & features of HTML5. Understand codes in HTML, CSS, Bootstrap, javascript and Jquery</li>
                                                <li><i className="fa fa-check"></i> Learn the fundamentals of implementing responsive web design. How to use bootstrap to create a web page.</li>
                                                <li><i className="fa fa-check"></i> How to use bootstrap to create a web page.</li>
                                                <li><i className="fa fa-check"></i> Adding symbols using Font Awesome.</li>
                                                
                                                
                                            </ul>
                                        </div>
                                       {/* <div className="event-details-speaker">
                                            <h5>Event Speakers</h5>
                                            <Row>
                                                {
                                                    Datas.map((data, i) => (
                                                        <Col lg="3" md="6" sm="6" key={i}>
                                                            <div className="event-speaker-item">
                                                                <img src={process.env.PUBLIC_URL + `/assets/images/${data.personImage}`} alt="" className="img-fluid" />
                                                                <div className="img-content text-center">
                                                                    <h7><b>{data.personName}</b> Manager, Microsoft</h7>
                                                                    
                                                                   
                                                                    <ul className="list-unstyled list-inline">
                                                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + data.socialLinks.facebook}><i className="fab fa-linkedin-in"></i></a></li>
                                                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + data.socialLinks.twitter}><i className="fab fa-twitter"></i></a></li>
                                                                        
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                        </div>

                                        <div className="pre-nxt-event">
                                            <Row>
                                                <Col md="6">
                                                    <div className="next-prev-item prev-event d-flex">
                                                        <div className="prev-img">
                                                            <img src={process.env.PUBLIC_URL + `/assets/images/gallery-03.jpg`} alt="" />
                                                        </div>
                                                        <div className="prev-text">
                                                            <p><Link to={process.env.PUBLIC_URL + "/event-details"}>Lorem, ipsum dolor sit amet consectetur adipisicing elit Perferendis.</Link></p>
                                                            <span> March 27, 2020</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="next-prev-item next-event d-flex flex-row-reverse text-right">
                                                        <div className="next-img">
                                                            <img src={process.env.PUBLIC_URL + `/assets/images/gallery-06.jpg`} alt="" />
                                                        </div>
                                                        <div className="next-text">
                                                            <p><Link to={process.env.PUBLIC_URL + "/event-details"}>Lorem, ipsum dolor sit amet consectetur adipisicing elit Perferendis.</Link></p>
                                                            <span> March 27, 2020</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>*/}
                                    </div>
                                </Col>

                                <Col lg="3" md="4" sm="12">
                                    <div className="event-details-sidebar">
                                        <Row>
                                            <Col md="12">
                                                <div className="event-sidebar-info">
                                                    <div className="event-sidebar-timer text-center">
                                                        <Timer initialTime={this.getDifferenceInSeconds('10/07/2021')} direction="backward">
                                                            <p><Timer.Days /><span>Days</span></p>
                                                            <p><Timer.Hours /><span>Hours</span></p>
                                                            <p><Timer.Minutes /><span>Minutes</span></p>
                                                        </Timer>
                                                    </div>
                                                    <ul className="list-unstyled event-info-list">
                                                        <li>Start Date: <span>Oct 7, 2021</span></li>
                                                        <li>Time: <span>05:00PM</span></li>
                                                        <li>Seat: <span>100</span></li>
                                                        <li>Place: <span>Zoom Meeting</span></li>
                                                        <li>Organizer: <span>SkillBhoomi</span></li>
                                                        <li>Phone: <span>9663205304</span></li>
                                                        <li>Email: <span>sales@skillbhoomi.com</span></li>
                                                        <li>Website: <span>http://skillbhoomi.com/</span></li>
                                                    </ul>
                                                    <button type="button" onClick={this.handleSubmit} className="buy-btn">Reserve Free Seat</button>
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <PopularCourse />
                                            </Col>
                                            <Col md="12">
                                               {/* <CourseTag />*/}
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default EventDetails