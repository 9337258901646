import React, { Component } from "react";
// import Datas from "../data/course/slider.json";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Swiper from "react-id-swiper";
import { Styles } from "./styles/courseSlider.js";
// import { API_URL } from "../utils/constants";
import { getAllCourses } from "../api/AuthServices";

class CourseSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.state = {};
  }
  componentDidMount() {
    this.CourseList();
  }

  CourseList() {
    getAllCourses().then((res) => {
      const courses = []
      const courses_0 = [];
      var secTitle=res.secTitle;
      console.log(secTitle);
      var courseList=res.dataList;
      console.log(courseList);

          for (let i = 0; i < courseList.length; i++) {
           if(i==3){
            var course_d=courseList[i];
            course_d["price"]= 15000;
            course_d["startingDate"]=1665801000000;
            courses_0.push(course_d);
           }
           else{
            courses.push(courseList[i]);
           }
            
          }
          console.log([...courses_0,...courses])
      //   console.log(res);
      this.setState({"secTitle":secTitle,"dataList":[...courses_0,...courses]});
    });
  }
  //   console.log();

  render() {
    const settings = {
      slidesPerView: 3,
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      spaceBetween: 30,
      watchSlidesVisibility: true,
      pagination: {
        el: ".slider-dot.text-center",
        clickable: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        576: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 3,
        },
      },
    };

    return (
      <Styles>
        {/* Course Slider */}
        <section className="course-slider-area">
          <Container>
            <Row>
              <Col md="12">
                <div className="sec-title text-center">
                  <h4>{this.state.secTitle}</h4>
                </div>
              </Col>
              <Col md="12" className="course-slider">
                <Swiper {...settings}>
                  {this.state.dataList &&
                    this.state.dataList.map((data, i) => (
                      <div className="course-item" key={i}>
                        <Link to={process.env.PUBLIC_URL + data.courseLink}>
                          <div
                            className="course-image"
                            style={{
                              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${data.imageUrl})`,
                            }}
                          >
                            <div className="author-img d-flex">
                              {/*<div className="img">
                                                                  <img src={process.env.PUBLIC_URL + `/assets/images/${data.authorImg}`} alt="" />
                                                              </div>*/}
                              <div className="title">
                                <p>{data.authorName}</p>
                                <span>{data.courseTitle}</span>
                              </div>
                            </div>
                            <div className={  data.id == 4  ?"course-discount" :""}>
                            {/* {data.id == 4 ? <button>30% OFF</button>:""} */}
                            </div>
                            <div className="course-price">
                              <p>₹{data.offerPrice}<span style={{fontSize:"13px" ,marginLeft:"10px"}}>{  data.id == 4  ?"₹"+ data.price :""}</span></p>
                            </div>
                           
                          </div>
                        </Link>
                        <div className="course-content">
                          <h6 className="heading">
                            <Link to={process.env.PUBLIC_URL + data.courseLink}>
                              {data.courseTitle}
                            </Link>
                          </h6>
                          <p className="desc">{data.courseDesc}</p>
                          <div className="course-face d-flex justify-content-between">
                            <div className="duration">
                              <p>
                                <i className="las la-clock"></i>
                                {new Date(data.startingDate)
                                  .toUTCString()
                                  .split(" ")
                                  .slice(1, 4)
                                  .join(" ")}
                              </p>
                            </div>
                            <div className="rating">
                              <ul className="list-unstyled list-inline">
                                <li className="list-inline-item">
                                  <i className="las la-star"></i>
                                </li>
                                <li className="list-inline-item">
                                  <i className="las la-star"></i>
                                </li>
                                <li className="list-inline-item">
                                  <i className="las la-star"></i>
                                </li>
                                <li className="list-inline-item">
                                  <i className="las la-star"></i>
                                </li>
                                <li className="list-inline-item">
                                  <i className="las la-star-half-alt"></i>
                                </li>
                                <li className="list-inline-item">(4.5)</li>
                              </ul>
                            </div>
                            <div className="student">
                              <p>
                                <i className="las la-chair"></i>25
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </Swiper>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
      //   <></>
    );
  }
}

export default CourseSlider;
