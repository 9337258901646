import React, { useEffect ,useState} from 'react';
import { Link,useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/account.js';
import * as authApi from '../../api/AuthServices';
import Select from 'react-select';
function Register() {
    const options = [
        { value: 'DevOps-Basics', label: 'DevOps with AWS' },
        { value: 'HeroInReactJs', label: 'Hero in ReactJs' },
        { value: 'JavaSpringBoot', label: 'Mastering Java Spring Boot Bootcamp' },
        { value: 'FullStackDeveloper', label: 'Fullstack Web Developer' },
        { value: 'Python', label: 'Python for data science and development' },
      ];
    let selectedCourse1= { value: 'DevOps-Basics', label: 'DevOps with AWS' };

    const location = useLocation();
    if(location.state)
    {
    console.log(location.state.courseId);
     selectedCourse1 = options.filter(course => course.value == location.state.courseId);
    }
    
    const [selectedCourse, setSelectedCourse] = useState(selectedCourse1);
    
    /*useEffect(() => {
        console.log(location.pathname); // result: '/secondpage'
        //console.log(location.search); // result: '?query=abc'
        let course = options.filter(course => course.value == "HeroInReactJs");
        console.log(course[0]);
        setSelectedCourse(course)
        
     }, [location]);*/
    useEffect(() => {
        
        const form = document.getElementById("form_registration");
        const fname = document.getElementById("registration_fname");
        const lname = document.getElementById("registration_lname");
        const email = document.getElementById("registration_email");
        const mobileNumber = document.getElementById("registration_mobile");
        const course = document.getElementsByName("registration_course");
        //setSelectedCourse( { value: 'Python', label: 'Python for data science and development' });
        // result: 'some_value'
        
        //const password = document.getElementById("registration_password");
       // const cpassword = document.getElementById("registration_cpassword");

        form.addEventListener("submit", formSubmit);
       
        async function formSubmit(e) {
            e.preventDefault();
            let success=true;
            //alert(JSON.stringify(course))
            const fnameValue = fname.value.trim();
            const lnameValue = lname.value.trim();
            const emailValue = email.value.trim();
            const mobileNumberValue = mobileNumber.value.trim();
            const courseValue = selectedCourse.value;
           // const qualificationValue = qualification.value.trim();
           // const passwordValue = password.value.trim();
           // const cpasswordValue = cpassword.value.trim();

            if (fnameValue === "") {
                setError(fname, "First name can't be blank");
                success=false;
            } else {
                setSuccess(fname);
            }

            if (lnameValue === "") {
                setError(lname, "Last name can't be blank");
                success=false;
            } else {
                setSuccess(lname);
            }

            if (emailValue === "") {
                setError(email, "Email can't be blank");
                success=false;
            } else if (!isEmail(emailValue)) {
                setError(email, "Not a valid email");
                success=false;
            } else {
                setSuccess(email);
            }

            if (mobileNumberValue === "") {
                setError(mobileNumber, "Mobile number can't be blank");
                success=false;
            } else {
                setSuccess(mobileNumber);
            }
           /* if (qualificationValue === "") {
                setError(qualification, "Qualification can't be blank");
                success=false;
            } else {
                setSuccess(qualification);
            }*/
           // alert(" selectedOption"+JSON.stringify(selectedOption))
            if(success)
            {
                let userData={};
                userData.studentName=fnameValue+" "+lnameValue
                userData.phoneNumber=mobileNumberValue
                userData.email=emailValue
                userData.qualification=courseValue
                userData.address=emailValue
                userData.courseName=courseValue
                try
                {
                    
                    console.log("userData",userData)
                    let res= await authApi.registerUser(userData);
                   // setRegistrationSuccess(successBox,"We have recieved your application. Someone from our team will get back to you.")
                    alert(" We have recieved your application. Someone from our team will get back to you.")
                }
                catch(e)
                {
                    alert("We are not able to save your data. Please try again.")
                }
            }
           

           /* if (cpasswordValue === "" || passwordValue !== cpasswordValue) {
                setError(cpassword, "Password doesn't match");
            } else {
                setSuccess(cpassword);
            }*/
        }

        function setError(input, message) {
            const formControl = input.parentElement;
            const errorMsg = formControl.querySelector(".registration_input-msg");
            formControl.className = "form-control text-left error";
            errorMsg.innerText = message;
        }
        function setRegistrationSuccess(input, message) {
            const formControl = input.parentElement;
            const errorMsg = formControl.querySelector(".registration_input-msg");
            formControl.className = "form-control text-left success";
            errorMsg.innerText = message;
        }

        function setSuccess(input) {
            const formControl = input.parentElement;
            formControl.className = "form-control success";
        }

        function isEmail(email) {
            return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
        }
    });
    const handleChange = selectedOption => {
        // this.setState({ selectedOption });
        setSelectedCourse(selectedOption);
       // setCourse(selectedOption);
         console.log(`Option selected:`, selectedOption);
       };
    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper registration-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="Registration" />

                {/* Registration Area */}
                <section className="registration-area">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="registration-box">
                                    <div className="registration-title text-center">
                                        <h3>Registration</h3>
                                        <span id="successBox" className="registration_input-msg"></span>
                                    </div>
                                    <form id="form_registration" className="form">
                                        <p className="form-control">
                                            <label htmlFor="registration_fname">First Name</label>
                                            <input type="text" placeholder="First name" id="registration_fname" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        <p className="form-control">
                                            <label htmlFor="registration_lname">Last Name</label>
                                            <input type="text" placeholder="Last name" id="registration_lname" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        <p className="form-control">
                                            <label htmlFor="registration_email">Email Address</label>
                                            <input type="email" placeholder="Email address" id="registration_email" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        <p className="form-control">
                                            <label htmlFor="registration_mobile">Mobile Number</label>
                                            <input type="text" placeholder="Mobile Number" id="registration_mobile" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        <p className="form-control">
                                            <label htmlFor="registration_course">Select Course</label>
                                            
                                        <Select
                                        defaultValue={selectedCourse}
                                        onChange={handleChange}
                                        options={options}
                                       // id="registration_course"
                                        name="registration_course"
                                        />
                                        <span className="registration_input-msg"></span>
                                        </p>
                                        { /* <p className="form-control">
                                            <label htmlFor="registration_qualification">Qualification</label>
                                            <input type="text" placeholder="E.g. B.tech, MCA" id="registration_qualification" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        
                                       <p className="form-control">
                                            <label htmlFor="registration_password">Password</label>
                                            <input type="password" placeholder="*******" id="registration_password" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        <p className="form-control">
                                            <label htmlFor="registration_cpassword">Confirm Password</label>
                                            <input type="password" placeholder="Confirm password" id="registration_cpassword" />
                                            <span className="registration_input-msg"></span>
                                        </p>*/}
                                        <button>Register Now</button>
                                    </form>
                                    <div className="have_account-btn text-center">
                                        {/* <p>Already have an account? <Link target="_blank" to={{pathname:"https://learn.skillbhoomi.com"}}>Login Here</Link></p> */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Footer 2 */}
                <FooterTwo />

            </div>
        </Styles>
    )
}

export default Register