import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
.hero-slider-area {
  .swiper-container {
    position: relative;

    .swiper-wrapper {
      .swiper-slide.slider-item {
        position: relative;

        .image-container {
          width: 100%;
          background-color: #82D2F0;
          text-align: right;
          display: block;
          overflow: hidden;
          position: relative;
          height: 500px;

          img.slider-image {
            width: 50%;
            height: 500px;
            padding-right: 50px;
            margin-top: 0;
            // transition: all 6000ms linear;
            // transform: scale(1);

            @media (max-width: 767px) {
              display: none;
            }
  
            @media (max-width: 575px) {
              display: none;
  
            }
          }

          @media (max-width: 767px) {
            height: 450px;
          
          }

          @media (max-width: 575px) {
            height: 360px;
          

          }
        }

        .slider-table {
          display: table;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 9999;

          .slider-tablecell-v1 {

            vertical-align: middle;

            .slider-box {
              .slider-title {
                text-align: center;
                p {
                  font-size: 18px;
                  color: white;
                  text-transform: uppercase;
                  margin-bottom: 8px;
                }
              }

              .slider-desc {
                width: 55%;
                float: left;
                @media (max-width: 767px) {
                  width:100%
                  
                  

                }

                h1 {
                  font-size: 46px;
                  color: #ffffff;
                  magin-left:30px;
                  margin-bottom: 32px;
                  font-weight: 600;

                  @media (max-width: 767px) {
                    font-size: 35px;
                    font-weight: 500;
                    

                  }
                }
              }

              .slider-btn {
                a.slider-btn1 {
                  font-size: 14px;
                  color: #fff;
                  background: ${colors.gr_bg};
                  display: inline-block;
                  width: 145px;
                  height: 40px;
                  text-align: center;
                  padding: 11px;
                  border-radius: 5px;

                  &:hover {
                    background: ${colors.gr_bg2};
                  }
                }

                a.slider-btn2 {
                  font-size: 14px;
                  color: #fff;
                  display: inline-block;
                  background: ${colors.bg1};
                  width: 145px;
                  height: 40px;
                  text-align: center;
                  padding: 11px;
                  border-radius: 5px;
                  margin-left: 15px;

                  &:hover {
                    background: ${colors.gr_bg};
                    border: none;
                    color: #ffffff;
                  }
                }
              }
              @media (max-width: 575px) {
                text-align: center !important;
              }
            }

            .slider-box2 {
              .slider-desc {
                h1 {
                  margin-left: auto;
                }
              }
            }
            .row-v1{
              margin-top:12%;
              margin-left:-143px;
              @media (max-width: 1500px) {
                margin-left:-25px;
              }
            }
           
          }

          .slider-tablecell-v2 {

            vertical-align: middle;
           .container{
              margin-left:90px;
              @media (max-width: 575px) {
                margin-left:0px;
              }
            }
            .slider-box {
              .slider-title {
                p {
                  font-size: 18px;
                  color: ${colors.border3};
                  text-transform: uppercase;
                  margin-bottom: 8px;
                }
              }

              .slider-desc {
                h1 {
                  font-size: 46px;
                  color: #ffffff;
                  magin-left:30px;
                  margin-bottom: 32px;
                  font-weight: 600;

                  @media (max-width: 767px) {
                    font-size: 18px;
                    font-weight: 500;
                  }
                }
                @media (max-width: 575px) {
                  padding:13px;
                  }
              }

              .slider-btn {
                a.slider-btn1 {
                  font-size: 14px;
                  color: #fff;
                  background: ${colors.gr_bg};
                  display: inline-block;
                  width: 145px;
                  height: 40px;
                  text-align: center;
                  padding: 11px;
                  border-radius: 5px;

                  &:hover {
                    background: ${colors.gr_bg2};
                  }
                }

                a.slider-btn2 {
                  font-size: 14px;
                  color: #fff;
                  display: inline-block;
                  background: ${colors.bg1};
                  width: 145px;
                  height: 40px;
                  text-align: center;
                  padding: 11px;
                  border-radius: 5px;
                  margin-left: 15px;

                  &:hover {
                    background: ${colors.gr_bg};
                    border: none;
                    color: #ffffff;
                  }
                }
              }
              @media (max-width: 575px) {
                text-align: center !important;
                font-size: 15px;
                font-weight: 500;
              }
            }

            .slider-box2 {
              .slider-desc {
                h1 {
                  margin-left: auto;
                }
              }
            }
          }

          .slider-tablecell {
            display: table-cell;
            vertical-align: middle;

            .slider-box {
              .slider-title {
                p {
                  font-size: 18px;
                  color: ${colors.border3};
                  text-transform: uppercase;
                  margin-bottom: 8px;
                }
              }

              .slider-desc {
                h1 {
                  font-size: 46px;
                  color: #ffffff;
                  max-width: 700px;
                  margin-bottom: 32px;
                  font-weight: 600;

                  @media (max-width: 767px) {
                    position:relative;
                    font-size: 26px;
                    font-weight: 500;
                    margin-top:70px;
                  }
                }
              }

              .slider-btn {
                a.slider-btn1 {
                  font-size: 14px;
                  color: #fff;
                  background: ${colors.gr_bg};
                  display: inline-block;
                  width: 145px;
                  height: 40px;
                  text-align: center;
                  padding: 11px;
                  border-radius: 5px;

                  &:hover {
                    background: ${colors.gr_bg2};
                  }
                }

                a.slider-btn2 {
                  font-size: 14px;
                  color: #fff;
                  display: inline-block;
                  background: ${colors.bg1};
                  width: 145px;
                  height: 40px;
                  text-align: center;
                  padding: 11px;
                  border-radius: 5px;
                  margin-left: 15px;

                  &:hover {
                    background: ${colors.gr_bg};
                    border: none;
                    color: #ffffff;
                  }
                }
              }
              @media (max-width: 575px) {
                text-align: center !important;
              }
            }

            .slider-box2 {
              .slider-desc {
                h1 {
                  margin-left: auto;
                }
              }
            }
            
            .slider-box3 {
              // margin-top:10%;
              .slider-desc {
                h1 {
                  margin-left: auto;
                  margin-bottom:0px;
                }
              }
              .slider-title1 {
                p {
                  font-size: 18px;
                  color: ${colors.border3};
                  text-transform: uppercase;
                  margin-bottom: 8px;
                }
                margin-top:-8%;

                @media (max-width: 575px) {
                  position:absolute;
                  left: 22%;
                  top: 70px;
                  text-align: center !important;
                }

              }
            }
            .slider-box-live-class {
              // margin-left: -80px;
              // width:120%;
              .slider-desc1 {
                h2 {
                  color: #ffffff;
                  max-width:500px;
                  margin-right: auto;
                }
                max-width: 700px;
                padding-top: 7%;
                padding-bottom: 2%;

                @media (max-width: 575px) {
                  h2{
                    font-size: 20px;
                  }
                  width:90%;
                  margin-top:-3%;
                  margin-left: 5%;
                  text-align: center !important;
                }

              }
              .slider-btn1 {
                width:100%;
                a.slider-btn-live {
                  font-size: 14px;
                  color: #fff;
                  background: ${colors.gr_bg};
                  display: inline-block;
                  width: 180px;
                  height: 40px;
                  text-align: center;
                  padding: 11px;
                  border-radius: 5px;

                  &:hover {
                    background: ${colors.gr_bg2};
                  }
                }
                @media (max-width: 575px) {
                  display: inline-block !important;
                  margin-top:20px;
                  // margin-left:7%;
                  margin-bottom:20%;
                  text-align: center !important;
                }
              }
              a.slider-btn-price {
                font-size: 14px;
                color: #fff;
                display: inline-block;
                background: ${colors.bg1};
                width: 180px;
                height: 40px;
                text-align: center;
                padding: 11px;
                border-radius: 5px;
                margin-left: 15px;

                &:hover {
                  color: #ffffff;
                  cursor :none;
                  cursor: default;
                }
                @media (max-width: 575px) {
                  text-align: center !important;
                }
              }
            }
            @media (max-width: 575px) {
              text-align: center !important;
            }
          }
            }
          }
        }
      }

      .swiper-slide.slider-item.swiper-slide-visible.swiper-slide-active {
        .image-container {
          img.slider-image {
            transform: scale(1.15);
          }
        }

        .slider-table {
          .slider-tablecell {
            .slider-title {
              animation-name: fadeInDown;
              animation-duration: 1s;
              animation-delay: 0.2s;
              animation-fill-mode: both;
            }
            .slider-title1 {
              animation-name: fadeInDown;
              animation-duration: 1s;
              animation-delay: 0.2s;
              animation-fill-mode: both;
            }

            .slider-desc {
              animation-name: fadeInUp;
              animation-duration: 1.5s;
              animation-delay: 0.2s;
              animation-fill-mode: both;
            }
            .slider-desc1 {
              animation-name: fadeInUp;
              animation-duration: 1.5s;
              animation-delay: 0.2s;
              animation-fill-mode: both;
            }

            .slider-btn {
              animation-name: fadeInUp;
              animation-duration: 2s;
              animation-delay: 0.2s;
              animation-fill-mode: both;
            }
            .slider-btn1 {
              animation-name: fadeInUp;
              animation-duration: 2s;
              animation-delay: 0.2s;
              animation-fill-mode: both;
            }
          }
        }
      }
    }

    .swiper-btn {
      position: absolute;
      top: 50%;
      left: 40px;
      width: 45px;
      height: 45px;
      font-size: 24px;
      color: ${colors.border1};
      text-align: center;
      padding-top: 5px;
      border-radius: 5px;
      transition: all 0.3s ease;
      margin-top: -35px;
      z-index: 111;

      i {
      }

      &:hover {
        background: ${colors.gr_bg};
        color: #ffffff;
      }
      @media (max-width: 575px) {
        left: 0px;
      }
    }

    .swiper-btn.slider-button-next {
      left: inherit;
      right: 40px;
      @media (max-width: 575px) {
        right: 0px;
      }
    }
  }
}
`;
