import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { Styles } from "./styles/tabBox.js";

class TabBox extends Component {

    render() {
        return (
          <Styles>
            {/* Tab Box Area */}
            <section className="tab-section">
              <Container>
                <Tab.Container defaultActiveKey="why">
                  <Row>
                    <Col lg="3" md="4">
                      <Nav className="flex-column">
                        <Nav.Item>
                          <Nav.Link eventKey="why">
                            <i className="las la-arrow-right"></i> Why
                            Skillbhoomi
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="mission">
                            <i className="las la-arrow-right"></i> Our Mission
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="vision">
                            <i className="las la-arrow-right"></i> Our Vision
                          </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                                            <Nav.Link eventKey="ranking"><i className="las la-arrow-right"></i> Our Ranking</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="research"><i className="las la-arrow-right"></i> Our Research</Nav.Link>
                                        </Nav.Item>*/}
                      </Nav>
                    </Col>
                    <Col lg="9" md="8">
                      <Tab.Content>
                        <Tab.Pane eventKey="why">
                          <h4 className="tab-title">Why Skillbhoomi</h4>
                          <p className="tab-desc">
                            Hands-on, hands-on and only hands on is our mantra.
                            Our courses are industry specific and validated by
                            many startups. Be it constant reminders, relentless
                            masters or 24 x 7 online support - we will
                            absolutely make sure that you run out of excuses to
                            not complete the course.
                          </p>
                          <ul className="list-unstyled check-list">
                            <li>
                              <i className="fa fa-check"></i>The course has been
                              designed with consultations of CTO, technical
                              architects and leads.The courses are completely
                              Job and Career oriented.
                            </li>
                            <li>
                              <i className="fa fa-check"></i>We have partnered
                              with many companies/corporates/startup for
                              placement support. We are ridiculously committed
                              to our students.
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Take classes on the
                              go with the Skillbhoomi. Stream or download to
                              watch on the plane, the subway, or wherever you
                              learn best.
                            </li>
                          </ul>
                        </Tab.Pane>
                        <Tab.Pane eventKey="mission">
                          <h4 className="tab-title">Our Mission</h4>
                          <p className="tab-desc">
                            We aim to create productive, successful, skilled
                            professionals who are relevant to the industry
                            demands at any time.
                          </p>
                          {/* <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                            </ul>*/}
                        </Tab.Pane>
                        <Tab.Pane eventKey="vision">
                          <h4 className="tab-title">Our Vision</h4>
                          <p className="tab-desc">
                            We see a world where everybody is skilled and
                            employed— united in the endeavour for the world
                            economic growth by developing skills for tomorrow.
                            Driven by our passion for skills development can
                            reduce un-employed and under-employed, increase
                            productivity, and improve standards of living.
                          </p>
                          {/* <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                            </ul>*/}
                        </Tab.Pane>
                        <Tab.Pane eventKey="ranking">
                          <h4 className="tab-title">Our Ranking</h4>
                          <p className="tab-desc">
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Voluptatum amet quo eius saepe et quis
                            necessitatibus hic natus facere a nisi fuga rem quas
                            molestias, eveniet minima molestiae. Lorem ipsum
                            dolor, sit amet consectetur adipisicing elit. Ea,
                            recusandae? Assumenda, error. Quam dicta iusto
                            saepe. Odit minus voluptas, fuga ipsum quia debitis
                            totam, tempore laudantium quasi dicta dolorem
                            deleniti.
                          </p>
                          <ul className="list-unstyled check-list">
                            <li>
                              <i className="fa fa-check"></i>Lorem ipsum dolor
                              sit amet, consectetur adipisicing elit. Voluptatum
                              amet quo eius saepe et quis necessitatibus hic
                              natus facere.
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Lorem ipsum dolor
                              sit amet, consectetur adipisicing elit. Voluptatum
                              amet quo eius saepe et quis necessitatibus hic
                              natus facere.
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Lorem ipsum dolor
                              sit amet, consectetur adipisicing elit. Voluptatum
                              amet quo eius saepe et quis necessitatibus hic
                              natus facere.
                            </li>
                          </ul>
                        </Tab.Pane>
                        <Tab.Pane eventKey="research">
                          <h4 className="tab-title">Our Research</h4>
                          <p className="tab-desc">
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Voluptatum amet quo eius saepe et quis
                            necessitatibus hic natus facere a nisi fuga rem quas
                            molestias, eveniet minima molestiae. Lorem ipsum
                            dolor, sit amet consectetur adipisicing elit. Ea,
                            recusandae? Assumenda, error. Quam dicta iusto
                            saepe. Odit minus voluptas, fuga ipsum quia debitis
                            totam, tempore laudantium quasi dicta dolorem
                            deleniti.
                          </p>
                          <ul className="list-unstyled check-list">
                            <li>
                              <i className="fa fa-check"></i>Lorem ipsum dolor
                              sit amet, consectetur adipisicing elit. Voluptatum
                              amet quo eius saepe et quis necessitatibus hic
                              natus facere.
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Lorem ipsum dolor
                              sit amet, consectetur adipisicing elit. Voluptatum
                              amet quo eius saepe et quis necessitatibus hic
                              natus facere.
                            </li>
                            <li>
                              <i className="fa fa-check"></i>Lorem ipsum dolor
                              sit amet, consectetur adipisicing elit. Voluptatum
                              amet quo eius saepe et quis necessitatibus hic
                              natus facere.
                            </li>
                          </ul>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Container>
            </section>
          </Styles>
        );
    }
}

export default TabBox
