// export const API_URL = "http://54.152.65.252:8080/app/api/v1";
// export const API_URL = "http://54.90.12.1:8080/app/api/v1";
export const API_URL = "http://54.210.220.143:8080/app/api/v1"
//export const API_URL = "http://54.90.12.1:8080/app/api/v1";

// role id of users
export const ADMIN_ROLE_ID = "02476e15-dfae-4068-8beb-e5bce310ee3e";
export const TEACHER_ROLE_ID = "05476e15-dfae-4068-8beb-e5bce310ee3e";
export const STUDENT_ROLE_ID = "03476e15-dfae-4068-8beb-e5bce310ee3e";

// API Endpoints
// groups related endpoints
export const CREATE_GROUP = `${API_URL}/group/createGroup`;

export const CREATE_SUB_GROUP = `${API_URL}/subGroup/createSubGroup`;
export const ALL_GROUPS = `${API_URL}/subGroup/listingGroups`;
export const GET_GROUP_DETAILS_BY_GROUP_ID = `${API_URL}/group/getGroupDetails/`;

// auth related endpoints
export const LOGIN = `${API_URL}/user/login`;
export const CHANGE_PASSWORD = `${API_URL}/auth/changepassword`;

// user related endpoints
export const REGISTER = `${API_URL}/form/registration`;


// discussions related endpoints
export const ADD_POST = `${API_URL}/post/save`;
export const GET_POSTS_LIST = `${API_URL}/post/getAllPosts`;
export const GET_POST_COMMENTS = `${API_URL}/post/getPostComments`;
export const UPDATE_POST = `${API_URL}/post/updatePost`;
export const UPDATE_STATUS = `${API_URL}/post/updateStatus`;
export const UPDATE_POST_COMMENT = `${API_URL}/postcomment/updateComment`;
export const ADD_POST_COMMENT = `${API_URL}/postcomment/save`;
export const UPLOAD_MEDIA = `${API_URL}/post/uploadFile`;
export const GET_USER_POST_BY_USER_ID = `${API_URL}/post/getUserPosts/`;
export const MARK_CMNT_CORRECT = `${API_URL}/postcomment/markCorrectComment`;

// queries related endpoints
export const GET_ALL_TICKETS = `${API_URL}/ticket/getAllTickets`;
export const CREATE_TICKET = `${API_URL}/ticket/create`;
export const GET_TICKET_BY_SEARCH_TEXT = `${API_URL}/ticket/getTicketBySearchText?searchText=`;



//Course related endpoints
export const GET_ALL_COURSES = `${API_URL}/courses/getAllCourses`;
export const GET_COURSE_DETAILS = `${API_URL}/courses/getCourseDetail/`; 
export const SAVE_REVIEW = `${API_URL}/reviews/saveReview`; 
