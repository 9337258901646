import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
    .corporate-training {
        padding: 0px;
        @media screen and (max-width: 1400px){
            min-height: 510px;
        }
        @media screen and (max-width: 800px){
            min-height: 440px;
        }
        @media screen and (max-width: 700px){
            min-height: 430px;
        }
        @media screen and (max-width: 600px){
            min-height: 340px;
        }
        @media screen and (max-width: 400px) {
            min-height: 294px;
        } 
    }
    .main-img-container{
        position: relative;
        width: 100%;
        padding: 0px;
    }
    .main-img{
        height: 300px;
        width:100%
    }    
    .btn-list {
        margin-top: -45px;    
        .course-icons {
            flex-direction: row;
            box-sizing: border-box;
            display: flex;
            place-content: center;
            align-items: center;            
        }
        .course-btn-1 {
            background-color: #f70808;
            width: 84px;
            height: 84px;
            margin-right: 12px;
            z-index: 1;
            border-radius: 50%;
            border: none;
            box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
            cursor: pointer;
            @media screen and (max-width: 600px){
                margin-right: 8px;
                width: 48px;
                height: 48px;
            }
        }
        .course-btn-2 {
            background-color: #e26003;
            width: 84px;
            height: 84px;
            margin-right: 12px;
            z-index: 1;
            border-radius: 50%;
            border: none;
            box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
            cursor: pointer;
            @media screen and (max-width: 600px){
                margin-right: 8px;
                width: 48px;
                height: 48px;
            }
        }
        .course-btn-3 {
            background-color: #e8a102;
            width: 84px;
            height: 84px;
            margin-right: 12px;
            z-index: 1;
            border-radius: 50%;
            border: none;
            box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
            cursor: pointer;
            @media screen and (max-width: 600px){
                margin-right: 8px;
                width: 48px;
                height: 48px;
            }
        }
        .course-btn-4 {
            background-color: #0071c6;
            width: 84px;
            height: 84px;
            margin-right: 12px;
            z-index: 1;
            border-radius: 50%;
            border: none;
            box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
            cursor: pointer;
            @media screen and (max-width: 600px){
                margin-right: 8px;
                width: 48px;
                height: 48px;
            }
        }
        .course-btn-5 {
            background-color: #2e367a;
            width: 84px;
            height: 84px;
            margin-right: 12px;
            z-index: 1;
            border-radius: 50%;
            border: none;
            box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
            cursor: pointer;
            @media screen and (max-width: 600px){
                margin-right: 8px;
                width: 48px;
                height: 48px;
            }
        }
        .course-btn-6 {
            background-color: #7f961e;
            width: 84px;
            height: 84px;
            margin-right: 12px;
            z-index: 1;
            border-radius: 50%;
            border: none;
            box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
            cursor: pointer;
            @media screen and (max-width: 600px){
                margin-right: 8px;
                width: 48px;
                height: 48px;
            }
        }
        @media screen and (max-width: 600px) {
            margin-top: -25px;
        }
    }
    .trusted-by {
        box-shadow: 0 0 13px #dddddd;
        .container {
            padding: 0;
        }
        ul {
            display: inline-flex;
            list-style: none;
            margin: 0;
            padding: 5px 0;
            vertical-align: middle;
            width: 100%;
            text-align: left;
            align-items: center;
            li {
                display: inline-block;
                line-height: 20px;
                margin: 0 20px;
                width: 100%;
                img {
                    width: 100%;
                    height: auto;
                }
                
            }
            .merclogo {
                text-align: center;
                img {
                    width: 60%;
                }
            }
            .trustedtxt {
                display: inline-block;
                color: #525e70;
                font-size: 15px;
                font-weight: 400;
                margin-right: 20px;
            }
        }
    }
    .shareformfeed {
        position: relative;
        overflow: hidden;
        background: url(/assets/images/bg_form.jpg) repeat-x;
        background-size: cover;

        .no-padding {
            padding: 0;
        }
        .container {
            padding: 50px 150px;
            @media only screen and (min-width: 300px) and (max-width: 767px) {
                padding: 15px;
            }
            .requestinfosec {
                display: inline-block;
                width: 100%;
                background: #ffffff;
                padding: 35px 65px;
                border-radius: 4px;
                @media only screen and (min-width: 300px) and (max-width: 767px){
                    padding: 25px 20px;
                }
                h2 {
                    color: #666666;
                    font-size: 22px;
                    line-height: 26px;
                    margin-bottom: 15px;
                    padding: 0;
                    text-align: left;
                    margin-top: 0px;
                    @media only screen and (min-width: 300px) and (max-width: 767px){
                        font-size: 18px;
                    }
                }
                .greenunderline {
                    display: inline-block;
                    width: 30px;
                    background: #6ac259;
                    height: 3px;
                    margin-bottom: 25px;
                }
            }
        }
        form.form6{
            .col-lg-12 {
                width: 100%;
            }

            

            
            .col-lg-6 {
                width: 50%;
                float: left;
            }

            .col-xs-12{
                width: 100%;
            }

            .corpnumval {
                border: 1px solid #f3f3f3;
                border-radius: 0;
                color: #222222;
                font-size: 15px;
                height: auto;
                padding: 9px 15px;
                box-shadow: none;
            }
            .intl-tel-input.allow-dropdown .flag-container, .intl-tel-input.separate-dial-code .flag-container {
                right: auto;
                left: 0;
            }
            .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 .selected-flag {
                width: 78px;
            }
            
            .intl-tel-input .country-list.dropup {
                bottom: 100%;
                margin-bottom: -1px;
            }
            
            .intl-tel-input .country-list .country.highlight {
                background-color: rgba(0, 0, 0, 0.05);
            }
            .intl-tel-input input, .intl-tel-input input[type=text], .intl-tel-input input[type=tel] {
                position: relative;
                z-index: 0;
                margin-top: 0 !important;
                margin-bottom: 0 !important;
                padding-right: 36px;
                margin-right: 0;
            }
            .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input, 
            .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type=text], 
            .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type=tel] {
                padding-left: 84px;
            }
            .intl-tel-input {
                position: relative;
                display: inline-block;
                width: 100%;
                .flag-container {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    padding: 1px;
                }
                .country-list {
                    position: absolute;
                    z-index: 2;
                    list-style: none;
                    text-align: left;
                    padding: 0;
                    margin: 0 0 0 -1px;
                    box-shadow: 1px 1px 4px rgb(0 0 0 / 20%);
                    background-color: white;
                    border: 1px solid #CCC;
                    white-space: nowrap;
                    max-height: 200px;
                    overflow-y: scroll;
                    width: 255px;
                    .country {
                        padding: 5px 10px;
                        white-space: initial !important;
                    }
                }
            }
            .formwrap{
                .form-group:nth-child(3n) {
                    padding-left: 15px;
                    padding-right: 0px !important;
                    @media only screen and (min-width: 300px) and (max-width: 767px){
                        padding-left: 0px;
                    }
                }
                .form-group {
                    margin-bottom: 20px;
                    position: relative;
                    min-height: 90px;
                }

                .form-group-phone {
                    padding-left: 15px;
                    @media only screen and (min-width: 300px) and (max-width: 767px){
                        padding-left: 0px;
                    }
                }
                .form-group label {
                    font-size: 14px;
                    color: #666666;
                    /* display: inline-block; */
                    margin-bottom: 8px;
                    font-weight: 400;
                    width: 100%;
                }
                .form-group-name{
                    @media only screen and (min-width: 300px) and (max-width: 767px){
                        padding-right: 0px;
                    }
                    input {
                        border: 1px solid #f3f3f3;
                        border-radius: 0;
                        color: #222222;
                        font-size: 15px;
                        height: auto;
                        padding: 9px 15px;
                        box-shadow: none;
                        width: 100%;
                    }
                }
                .form-group-companyname{
                    @media only screen and (min-width: 300px) and (max-width: 767px){
                        padding-right: 0px;
                    }
                    input {
                        border: 1px solid #f3f3f3;
                        border-radius: 0;
                        color: #222222;
                        font-size: 15px;
                        height: auto;
                        padding: 9px 15px;
                        box-shadow: none;
                    }
                }
                .learnercount{
                    @media only screen and (min-width: 300px) and (max-width: 767px){
                        padding-right: 0px;
                    }
                    input {
                        border: 1px solid #f3f3f3;
                        border-radius: 0;
                        color: #222222;
                        font-size: 15px;
                        height: auto;
                        padding: 9px 15px;
                        box-shadow: none;
                    }
                }
                .form-group-email{
                    @media only screen and (min-width: 300px) and (max-width: 767px){
                        padding-right: 0px;
                    }
                    input {
                        border: 1px solid #f3f3f3;
                        border-radius: 0;
                        color: #222222;
                        font-size: 15px;
                        height: auto;
                        padding: 9px 15px;
                        box-shadow: none;
                    }
                }
                .form-group-query {
                    padding-left: 0px !important;
                    textarea {
                        border: 1px solid #f3f3f3;
                        border-radius: 0;
                        color: #222222;
                        font-size: 15px;
                        height: auto;
                        padding: 9px 15px;
                        box-shadow: none;
                        resize: none;
                    }
                }
                .form-groupbtn > button {
                    background: ${colors.gr_bg};
                    border: 0 none;
                    border-radius: 3px;
                    color: #ffffff;
                    font-size: 17px;
                    padding: 10px 40px;
                    text-transform: uppercase;
                    &:hover {
                        background: ${colors.gr_bg2};
                    }
                }
            }
            .courselistbtnsec {
                margin: 0 auto;
                text-align: right;
            }
            .btn {
                /* display: inline-block; */
                padding: 6px 12px;
                margin-bottom: 0;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.42857143;
                text-align: center;
                white-space: nowrap;
                vertical-align: middle;
                touch-action: manipulation;
                cursor: pointer;
                background-image: none;
                border: 1px solid transparent;
                border-radius: 4px;
            }
            .form-control {
                display: block;
                width: 100%;
                height: 34px;
                padding: 6px 12px;
                font-size: 14px;
                line-height: 1.42857143;
                color: #555;
                background-color: #fff;
                background-image: none;
                border: 1px solid #ccc;
                border-radius: 4px;
            }
            label{
                font-size:16px;
            }
            
        }
        form.form6{
             p.form-controls {
            padding   : 0;
            width     : auto;
            height    : auto;
            background: transparent;
            border    : none;
            margin-bottom : 30px;
            position  : relative;

            textarea {
                width           : 100%;
                height          : 150px;
                background      : transparent;
                border          : 1px solid ${colors.border3};
                font-size       : 15px;
                padding         : 15px 20px;
                color           : ${colors.black2};
                border-radius   : 5px;

                &:focus {
                    border-color: ${colors.green};
                }

                &::placeholder {
                    font-size  : 15px;
                    color      : ${colors.text2};
                }
            }
            

            input {
                width           : 100%;
                height          : 50px;
                background      : transparent;
                border          : 1px solid ${colors.border3};
                font-size       : 15px;
                padding         : 15px 20px;
                color           : ${colors.black2};
                border-radius   : 5px;

                &:focus {
                    border-color: ${colors.green};
                }

                &::placeholder {
                    font-size  : 15px;
                    color      : ${colors.text2};
                }
            }
            select{
                width           : 100%;
                height          : 50px;
                background      : transparent;
                border          : 1px solid ${colors.border3};
                font-size       : 15px;
                padding         : 15px 20px;
                color           : ${colors.black2};
                border-radius   : 5px;

                &:focus {
                    border-color: ${colors.green};
                }

                &::placeholder {
                    font-size  : 15px;
                    color      : ${colors.text2};
                }
               
            }

            span {
                color      : ${colors.red};
                font-weight: 500;
                position   : absolute;
                bottom     : -22px;
                left       : 0;
                visibility : hidden;
            }
        }

        p.form-controls.success {
            textarea,
            input,select {
                border: 2px solid #28a745 !important;
            }

            &::before {
                position   : absolute;
                content    : "\f058";
                font-family: "Line Awesome Free";
                font-size  : 24px;
                color      : ${colors.green}!important;
                font-weight: 900;
                top        : 8px;
                right      : 10px;
            }
        }

        p.form-controls.error {
            textarea,
            input,select {
                border: 2px solid ${colors.red}!important;
            }

            &::before {
                position   : absolute;
                content    : "\f06a";
                font-family: "Line Awesome Free";
                font-size  : 24px;
                color      : ${colors.red}!important;
                font-weight: 900;
                top        : 8px;
                right      : 10px;
            }
        }

        p.form-controls.error {
            span {
                visibility: visible;
            }
        }

        button {
            font-size : 15px;
            color     : #fff;
            background: ${colors.gr_bg};
            width     : 200px;
            height    : 50px;
            border    : none;
            font-weight: 500;
            border-radius : 5px;
            margin-top: 5px;
            margin-left:auto;

            &:hover {
                background: ${colors.gr_bg2};
            }
        }

        }

    }

    
    .corpfeatures {
        background: #ffffff url(/assets/images/corp_training_bg.png) no-repeat center center;
        display: inline-block;
        width: 100%;
        .title {
            color: #5271a3;
            font-size: 31px;
            font-weight: 600;
            margin: 10px auto;
            text-align: center;
            display: inline-block;
            width: 100%;
            @media only screen and (min-width: 300px) and (max-width: 767px){
                font-size: 21px;
            }
        }
        .container {
            padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto;
        }
        .frs-main {
            margin-bottom: 120px;
            display: inline-block;
            @media only screen and (min-width: 300px) and (max-width: 767px){
                margin-bottom: 60px;
                padding: 0px;
            }
            .corpfeatleft {
                padding-right: 115px;
                display: inline-block;
                position: relative;
                padding-left: 0;
                .featcardmain {
                    width: 345px;
                    height: 150px;
                    display: inline-block;
                    padding: 20px 25px 15px 25px;
                    border-radius: 4px;
                    background: #ffffff;
                    box-shadow: 4px 4px 10px #e8edf1;
                    margin-bottom: 35px;
                    text-decoration: none;
                    cursor: pointer;
                    outline: 0;
                    .featdesc {
                        color: #3d4d66;
                        font-size: 14px;
                        font-weight: 300;
                        display: inline-block;
                        width: 100%;
                        text-align: left;
                        margin: 0;
                    }
                    .header {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        margin-bottom: 12px;
                        .imgcont {
                            width: 55px;
                            height: 55px;
                            display: inline-block;
                            margin-right: 20px;
                        }
                        .featuretitle {
                            font-size: 19px;
                            font-weight: 600;
                            color: #525f78;
                            display: inline-block;
                            width: auto;
                        }
                    }
                }
                .leftfeatcontentsec {
                    height: 520px;
                    width: 300px;
                    padding: 30px 25px;
                    display: none;
                    position: absolute;
                    background: #ffffff;
                    border-radius: 5px;
                    box-shadow: 4px 4px 10px #e8edf1;
                    top: 0;
                    right: -150px;
                    @media only screen and (min-width: 300px) and (max-width: 767px){
                        height: 100%;
                        width: 100%;
                        padding: 30px 25px;
                        position: absolute;
                        top: 0;
                        right: 0;
                        position: fixed;
                        left: 0;
                        z-index: 999;
                        margin-top: 30px;
                    }
                    .coursefeatlist {
                        display: inline-block;
                        width: 100%;
                        list-style-type: disc;
                        list-style-position: inside;
                        padding-left: 0;
                        li {
                            color: #f5b1ae;
                            margin-bottom: 10px;
                            span {
                                display: inline-block;
                                text-align: left;
                                color: #3d4d66;
                                font-size: 15px;
                                font-weight: 300;
                                width: 90%;
                                vertical-align: text-top;
                            }
                        }
                    }
                }

            }

            .corpfeatright {
                padding-left: 115px;
                display: inline-block;
                position: relative;
                padding-right: 0;
                .featcardmain {
                    width: 345px;
                    height: 150px;
                    display: inline-block;
                    padding: 20px 25px 15px 25px;
                    border-radius: 4px;
                    background: #ffffff;
                    box-shadow: 4px 4px 10px #e8edf1;
                    margin-bottom: 35px;
                    text-decoration: none;
                    cursor: pointer;
                    outline: 0;
                    float: right;
                    .featdesc {
                        color: #3d4d66;
                        font-size: 14px;
                        font-weight: 300;
                        display: inline-block;
                        width: 100%;
                        text-align: left;
                        margin: 0;
                    }
                    .header {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        margin-bottom: 12px;
                        .imgcont {
                            width: 55px;
                            height: 55px;
                            display: inline-block;
                            margin-right: 20px;
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                        .featuretitle {
                            font-size: 19px;
                            font-weight: 600;
                            color: #525f78;
                            display: inline-block;
                            width: auto;
                        }
                    }
                }
                .rightfeatcontentsec {
                    height: 520px;
                    width: 300px;
                    padding: 30px 25px;
                    display: none;
                    position: absolute;
                    background: #ffffff;
                    border-radius: 5px;
                    box-shadow: 4px 4px 10px #e8edf1;
                    top: 0;
                    left: -150px;
                    .coursefeatlist {
                        display: inline-block;
                        width: 100%;
                        list-style-type: disc;
                        list-style-position: inside;
                        padding-left: 0;
                        li {
                            color: #f5b1ae;
                            margin-bottom: 10px;
                            span {
                                display: inline-block;
                                text-align: left;
                                color: #3d4d66;
                                font-size: 15px;
                                font-weight: 300;
                                width: 90%;
                                vertical-align: text-top;
                            }
                        }
                    }
                }
            }


        }
    }
    .visible-xs {
        @media (max-width: 767px){
            display: block!important;
        }
    }
    .show{
        display: inline-block!important;
    }
    .job-ready {
        height: calc(400px) !important;
        justify-content: space-between !important;
    }
    .misc-content {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: start;

        .txt-color {
            color: #001a33;
            font-size: 25px;
            font-weight: 700!important;
            font-size: calc(1.325rem + .9vw)!important;
        }
        .div-sub{
            display: flex;
            width: 50%;
            justify-content: space-around;
            @media screen and (max-width: 600px) {
                height: calc(400px) !important;
                justify-content: space-between !important;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }
        .sub-txt {
            color: #001a33;
            font-size: 14px;
        }
        @media screen and (max-width: 600px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
    
    .hiretalent-container {
        width: 100%;
        position: absolute;
        top: 180px;
        left: 0;
        .hiretalent-btn{
            text-align: center;
            margin-right: 10px;
        }
        .slider-btn {
            a.slider-btn1 {
                font-size : 14px;
                color     : #fff;
                background: ${colors.gr_bg};
                display   : inline-block;
                width     : 160px;
                height    : 40px;
                text-align: center;
                padding   : 11px;
                border-radius : 5px;

                &:hover {
                    background: ${colors.gr_bg2};
                }
            }
    }
    .tc-container {
        width: 100%;
        position: absolute;
        bottom: -73px;
        .hiretalent-tc{
            text-align: right;
            margin-right: 50px;
            color: white;
            position: absolute;
            right: 20px;
            bottom: 30px;
            @media screen and (max-width: 1400px){
                bottom: 20px;
            }
            @media screen and (max-width: 800px){
                right: 10px;
            }
            @media screen and (max-width: 600px){
                right: unset;
                left: 10px;
                bottom: 50px;
            }
            @media screen and (max-width: 400px){
                font-size: 12px;
            }
            
        }
    }
    @media(max-width: 767px) {
        padding: 30px 0 40px;
    }   
`;
