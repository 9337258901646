import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';

import FooterTwo from '../../components/FooterTwo';
import { Styles } from '../contact/styles/contact.js';

function HireFromUs() {
    useEffect(() => {
        const form = document.getElementById("form_contact");
        const name = document.getElementById("contact_name");
        const email = document.getElementById("contact_email");
        const subject = document.getElementById("contact_subject");
        const message = document.getElementById("contact_message");

        form.addEventListener("submit", formSubmit);

        function formSubmit(e) {
            let success=true;
            e.preventDefault();

            const nameValue = name.value.trim();
            const emailValue = email.value.trim();
            const subjectValue = subject.value.trim();
            const messageValue = message.value.trim();

            if (nameValue === "") {
                setError(name, "Name can't be blank");
                success=false;
            } else {
                setSuccess(name);
            }

            if (emailValue === "") {
                setError(email, "Mobile can't be blank");
                success=false;
            }else {
                setSuccess(email);
            }

            if (subjectValue === "") {
                setError(subject, "Course can't be blank");
                success=false;
            } else {
                setSuccess(subject);
            }

            if (messageValue === "") {
                setError(message, "Message can't be blank");
                success=false;
            } else {
                setSuccess(message);
            }
            if(success)
            {
                alert(" Thank you for your application. We will get back to you soon!!!")
            }
        }

        function setError(input, message) {
            const formControl = input.parentElement;
            const errorMsg = formControl.querySelector(".contact_input-msg");
            formControl.className = "form-control text-left error";
            errorMsg.innerText = message;
        }

        function setSuccess(input) {
            const formControl = input.parentElement;
            formControl.className = "form-control success";
        }

        
    });

    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper contact-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="Hire From Us" />

                {/* Contact Area */}
                <section className="contact-area">
                    <Container>
                        <Row>
                            <Col md="6">
                                <div className="contact-box-title">
                                    <h4>Start your hiring</h4>
                                </div>
                                <div className="contact-icon-box d-flex">
                                    
                                    <div className="box-content">
                                    <h5 >Why Skillbhoomi</h5>
                                            <p className="tab-desc">Hands-on, hands-on and only hands on is our mantra. Our courses are industry specific and validated by many startups. You dont need to give additional training to our candidates. They can be easily deployable to your projects within 15 days after hiring.</p>
                                            <ul className="list-unstyled">
                                                <li><i className="fa fa-check"></i>Our courses has been designed with consultations of CTO & technical architects.</li>
                                                <li><i className="fa fa-check"></i>Our candidates are trained by industry experts and mentors.</li>
                                                <li><i className="fa fa-check"></i>Our candidates has gone through 200+ coding hours in real-time projects.</li>
                                                <li><i className="fa fa-check"></i>High quality candidates with ready to join at zero hiring cost.</li>
                                            
                                            </ul>
                                    </div>
                                </div>
                                
                                
                                
                            </Col>
                            <Col md="6">
                                <div className="contact-form">
                                    <div className="form-title">
                                        <h4>Fill in the form and we will get back to you!</h4>
                                    </div>
                                    <div className="form-box">
                                        <form id="form_contact" className="form">
                                            <Row>
                                                <Col md="6">
                                                    <p className="form-control">
                                                        <input type="text" placeholder="Full Name" id="contact_name" />
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>
                                                <Col md="6">
                                                    <p className="form-control">
                                                        <input type="text" placeholder="Mobile" id="contact_email" />
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>
                                                <Col md="12">
                                                    <p className="form-control">
                                                        <input type="text" placeholder="Company name" id="contact_subject" />
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>
                                                <Col md="12">
                                                    <p className="form-control">
                                                        <textarea name="message" id="contact_message" placeholder="Tell us your hiring requirement"></textarea>
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>
                                                <Col md="12">
                                                    <button>Start Hiring</button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    
                </section>

                {/* Footer 2 */}
                <FooterTwo />

            </div>
        </Styles>
    )
}

export default HireFromUs