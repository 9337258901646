import React, { useEffect, useState } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import HeaderTwo from "../../components/HeaderTwo";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import ReviewForm from "./components/ReviewForm";
import PopularCourse from "./components/PopularCourse";
import CourseTag from "./components/CourseTag";
import FooterTwo from "../../components/FooterTwo";
import { Styles } from "./styles/course.js";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import devops from "../../data/course/devOps.json";
import reactJs from "../../data/course/reactJs.json";
import fullStack from "../../data/course/fullStack.json";
import springBoot from "../../data/course/springBoot.json";
import python from "../../data/course/python.json";
import { getCourseDetails } from "../../api/AuthServices";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function CourseDetails() {
  //   const [course, setCourse] = useState(devops);
  const history = useHistory();
  let { id } = useParams();
  let query = useQuery();
    const [c, setC] = useState(0);
  const [courseDetails, setCourseDetails] = useState({});
  const [featureList, setFeatureList] = useState([]);
  const [learningOutcome, setLearningOutcome] = useState([]);
  let courseId = query.get("id");
  useEffect(() => {
    // console.log("couseId==", courseId);
    if (courseId !== null) {
      getCourseDetails(courseId).then((res) => {
        // console.log("Course details==" + res);
        setCourseDetails(res);
      });
    }
console.log("idcds",courseId);
   
    // if (courseId == 1) {
    //   setCourse(devops);
    // } else if (courseId == 2) {
    //   setCourse(fullStack);
    // } else if (courseId == 3) {
    //   setCourse(springBoot);
    // } else if (courseId == 4) {
    //   setCourse(reactJs);
    // } else if (courseId == 5) {
    //   setCourse(python);
    // }

    // setC(c + 1);

    const courseButton = document.querySelectorAll(".course-button");
    courseButton.forEach((button) => {
      button.addEventListener("click", () => {
        button.classList.toggle("active");
        const content = button.nextElementSibling;

        if (button.classList.contains("active")) {
          content.className = "course-content show";
          content.style.maxHeight = content.scrollHeight + "px";
        } else {
          content.className = "course-content";
          content.style.maxHeight = "0";
        }
      });
    });
  }, [courseId]);

  if(featureList.length == 0){
    let det = courseDetails?.overview?.featureList;
    let learn = courseDetails?.project?.learningOutcomeList;
    //  console.log(det);
    if (det !== undefined && learn !== undefined) {
      let feature = JSON.parse(det);
      let learningOutcome = JSON.parse(learn);
      setFeatureList(feature);
      setLearningOutcome(learningOutcome);
    }
  }
  let date = new Date(courseDetails?.course?.startingDate);
  if(courseId == 4){
     date = new Date(1665801000000);
  }
 
  
 
  
  let dateToStr = date.toUTCString().split(" ");

  function abc(data){
    let date = new Date(data);
    let dateToStr = date.toUTCString().split(" ");
    let time = dateToStr[4].split(":");
    let t = time[0]+":"+time[1];
    return (
      dateToStr[1] +
      " " +
      dateToStr[2] +
      "," +
      dateToStr[3] +
      " " +
      "|" +
      " " +
      t
    );
  }
  // console.log(featureList);
  // console.log(date.toLocaleDateString());
  console.log(courseDetails);
  // console.log(dateToStr);
  // console.log(dateToStr[2]+" "+ dateToStr[1]+","+ dateToStr[3]);

  //   console.log(JSON.parse(courseDetails.));

  //   console.log(JSON.parse(courseDetails.overview.featureList));
  //   let feature = (courseDetails.overview.feature)
  //   console.log(feature);

    const handleSubmit = (employee) => {
      let courseId = query.get("id");
      let courseName = "DevOps-Basics";
      if (courseId == 1) {
        courseName = "DevOps-Basics";
      } else if (courseId == 2) {
        courseName = "FullStackDeveloper";
      } else if (courseId == 3) {
        courseName = "SpringBootWithMysql";
      } else if (courseId == 4) {
        courseName = "HeroInReactJs";
      } else if (courseId == 5) {
        courseName = "Python";
      }
      if (courseId == 2) {
        history.push({
          pathname: "/registration_devops",

          state: { courseId: courseName },
        });
      } else {
        history.push({
          pathname: "/registration",

          state: { courseId: courseName },
        });
      }
    };

  return (
    <div className="main-wrapper course-details-page">
      {/* Header 2 */}
      <HeaderTwo />
      {/* Breadcroumb */}
      <BreadcrumbBox title="Course Details" />
      <Styles>
        {/* Course Details */}
        <section className="course-details-area">
          <Container>
            <Row>
              <Col lg="9" md="8" sm="12">
                <div className="course-details-top">
                  <div className="heading">
                    <h4>
                      {courseDetails && courseDetails?.course?.courseTitle} (
                      {courseDetails && courseDetails?.course?.authorName}).
                    </h4>
                  </div>
                  <div className="course-top-overview">
                    <div className="d-flex overviews">
                      <div className="author">
                        {courseId && courseId == 3 ? (
                          <img
                          src={
                            process.env.PUBLIC_URL + `/assets/images/logosb12.png`
                          }
                          alt=""
                          width="40"
                          height="40"
                        />
                        ) : (
                          <img
                          src={
                            process.env.PUBLIC_URL + `/assets/images/author.jpg`
                          }
                          alt=""
                        />
                        )}
                        
                        <div className="author-name">
                          <h6>Author</h6>
                          <p>
                            {courseDetails && courseDetails?.course?.authorName}
                          </p>
                        </div>
                      </div>
                      <div className="category">
                        <h6>Category</h6>
                        <p>
                          {courseDetails && courseDetails?.course?.category}
                        </p>
                      </div>
                      <div className="rating">
                        <h6>Rating</h6>
                        <ul className="list-unstyled list-inline">
                          <li className="list-inline-item">
                            <i className="las la-star"></i>
                          </li>
                          <li className="list-inline-item">
                            <i className="las la-star"></i>
                          </li>
                          <li className="list-inline-item">
                            <i className="las la-star"></i>
                          </li>
                          <li className="list-inline-item">
                            <i className="las la-star"></i>
                          </li>
                          <li className="list-inline-item">
                            <i className="las la-star-half-alt"></i>
                          </li>
                          <li className="list-inline-item">
                            {courseDetails && courseDetails?.course?.rating}
                          </li>
                        </ul>
                      </div>
                      <div className="price">
                        <h6>Price</h6>
                        <p>
                          {courseDetails && courseDetails?.course?.offerPrice}
                          &nbsp;&nbsp;
                          <span style={{ textDecoration: "line-through" }}>
                            {courseDetails && courseDetails?.course?.price}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="course-details-banner">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        `/assets/images/` +
                        courseDetails?.course?.imageUrl
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="course-tab-list">
                    <Tab.Container defaultActiveKey="overview">
                      <Nav className="flex-column">
                        <Nav.Item>
                          <Nav.Link eventKey="overview">Overview</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="curriculum">Curriculum</Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                                                    <Nav.Link eventKey="instructor">Instructors</Nav.Link>
                                                </Nav.Item>*/}
                        <Nav.Item>
                          <Nav.Link eventKey="project">Project</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="review">Reviews</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="overview" className="overview-tab">
                          <div className="course-desc">
                            <h5>Course Description</h5>
                            <p>
                              {courseDetails &&
                                courseDetails?.overview?.courseDescription}
                            </p>
                          </div>
                          <div className="course-feature">
                            <h5>Course Feature</h5>
                            <p>{courseDetails?.overview?.courseFeature}</p>
                            <ul className="list-unstyled">
                              {featureList?.map((data, i) => (
                                <li>
                                  <i className="las la-arrow-right"></i>
                                  {data.feature}
                                </li>
                              ))}
                            </ul>
                          </div>
                          {/*  <div className="course-learn">
                                                        <h5>Learning Outcome</h5>
                                                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi, quidem facere quisquam aperiam neque dolorem saepe. Laboriosam, quam aliquam odit modi harum libero culpa distinctio.</p>
                                                        <ul className="list-unstyled">
                                                            <li><i className="fa fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi.</li>
                                                            <li><i className="fa fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi.</li>
                                                            <li><i className="fa fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi.</li>
                                                            <li><i className="fa fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi.</li>
                                                        </ul>
                                                    </div>
                                                   <div className="course-share">
                                                        <h5>Share This Course</h5>
                                                        <ul className="social list-unstyled list-inline">
                                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>
                                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>
                                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-youtube"></i></a></li>
                                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-dribbble"></i></a></li>
                                                        </ul>
                                                    </div>*/}
                        </Tab.Pane>
                        <Tab.Pane
                          eventKey="curriculum"
                          className="curriculum-tab"
                        >
                          <div className="course-curriculum">
                            <h5>Course Curriculum</h5>
                            <p>{courseDetails?.curriculum?.courseCurriculum}</p>
                          </div>
                          <div className="course-element">
                            <h5>Course Content</h5>
                            {courseDetails?.curriculum?.courseModuleList?.map(
                              (data, i) => (
                                <div className="course-item">
                                  <button className="course-button active">
                                    Part {i + 1}: {data?.name}
                                    <span>
                                      {data?.topicList?.length} Lectures - 30
                                      Min
                                    </span>
                                  </button>
                                  <div className="course-content show">
                                    <ul className="list-unstyled">
                                      {data?.topicList?.map((chapter, i) => (
                                        <li>
                                          <span className="play-icon">
                                            <i className="las la-play"></i>{" "}
                                            Lecture: {i + 1}
                                          </span>
                                          <span className="lecture-title">
                                            {chapter?.name}
                                          </span>
                                          <span className="lecture-duration">
                                            {chapter?.duration}
                                          </span>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </Tab.Pane>
                        <Tab.Pane
                          eventKey="instructor"
                          className="instructor-tab"
                        >
                          <h5>Course Instructors</h5>
                          <div className="instructor-item">
                            <Row>
                              <Col md="4">
                                <div className="instructor-img">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      `/assets/images/instructor-1.jpg`
                                    }
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </Col>
                              <Col md="8">
                                <div className="instructor-content">
                                  <div className="instructor-box">
                                    <div className="top-content d-flex justify-content-between">
                                      <div className="instructor-name">
                                        <h6>Mark Shadow</h6>
                                        <p>Senior Lecturer</p>
                                      </div>
                                      <div className="instructor-social">
                                        <ul className="social list-unstyled list-inline">
                                          <li className="list-inline-item">
                                            <a
                                              href={
                                                process.env.PUBLIC_URL + "/"
                                              }
                                            >
                                              <i className="fab fa-facebook-f"></i>
                                            </a>
                                          </li>
                                          <li className="list-inline-item">
                                            <a
                                              href={
                                                process.env.PUBLIC_URL + "/"
                                              }
                                            >
                                              <i className="fab fa-twitter"></i>
                                            </a>
                                          </li>
                                          <li className="list-inline-item">
                                            <a
                                              href={
                                                process.env.PUBLIC_URL + "/"
                                              }
                                            >
                                              <i className="fab fa-linkedin-in"></i>
                                            </a>
                                          </li>
                                          <li className="list-inline-item">
                                            <a
                                              href={
                                                process.env.PUBLIC_URL + "/"
                                              }
                                            >
                                              <i className="fab fa-youtube"></i>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="instructor-desk">
                                      <p>
                                        Lorem ipsum dolor sit amet consectetur
                                        adipisicing elit. Quae perferendis
                                        delectus voluptate reiciendis animi nisi
                                        nemo tenetur sequi cum laudantium sit
                                        totam libero quasi ducimus accusantium
                                        numquam eaque.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className="instructor-item">
                            <Row>
                              <Col md="4">
                                <div className="instructor-img">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      `/assets/images/instructor-2.jpg`
                                    }
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </Col>
                              <Col md="8">
                                <div className="instructor-content">
                                  <div className="instructor-box">
                                    <div className="top-content d-flex justify-content-between">
                                      <div className="instructor-name">
                                        <h6>Katrin Kay</h6>
                                        <p>Senior Lecturer</p>
                                      </div>
                                      <div className="instructor-social">
                                        <ul className="social list-unstyled list-inline">
                                          <li className="list-inline-item">
                                            <a
                                              href={
                                                process.env.PUBLIC_URL + "/"
                                              }
                                            >
                                              <i className="fab fa-facebook-f"></i>
                                            </a>
                                          </li>
                                          <li className="list-inline-item">
                                            <a
                                              href={
                                                process.env.PUBLIC_URL + "/"
                                              }
                                            >
                                              <i className="fab fa-twitter"></i>
                                            </a>
                                          </li>
                                          <li className="list-inline-item">
                                            <a
                                              href={
                                                process.env.PUBLIC_URL + "/"
                                              }
                                            >
                                              <i className="fab fa-linkedin-in"></i>
                                            </a>
                                          </li>
                                          <li className="list-inline-item">
                                            <a
                                              href={
                                                process.env.PUBLIC_URL + "/"
                                              }
                                            >
                                              <i className="fab fa-youtube"></i>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="instructor-desk">
                                      <p>
                                        Lorem ipsum dolor sit amet consectetur
                                        adipisicing elit. Quae perferendis
                                        delectus voluptate reiciendis animi nisi
                                        nemo tenetur sequi cum laudantium sit
                                        totam libero quasi ducimus accusantium
                                        numquam eaque.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className="instructor-item">
                            <Row>
                              <Col md="4">
                                <div className="instructor-img">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      `/assets/images/instructor-3.jpg`
                                    }
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </Col>
                              <Col md="8">
                                <div className="instructor-content">
                                  <div className="instructor-box">
                                    <div className="top-content d-flex justify-content-between">
                                      <div className="instructor-name">
                                        <h6>David Show</h6>
                                        <p>Senior Lecturer</p>
                                      </div>
                                      <div className="instructor-social">
                                        <ul className="social list-unstyled list-inline">
                                          <li className="list-inline-item">
                                            <a
                                              href={
                                                process.env.PUBLIC_URL + "/"
                                              }
                                            >
                                              <i className="fab fa-facebook-f"></i>
                                            </a>
                                          </li>
                                          <li className="list-inline-item">
                                            <a
                                              href={
                                                process.env.PUBLIC_URL + "/"
                                              }
                                            >
                                              <i className="fab fa-twitter"></i>
                                            </a>
                                          </li>
                                          <li className="list-inline-item">
                                            <a
                                              href={
                                                process.env.PUBLIC_URL + "/"
                                              }
                                            >
                                              <i className="fab fa-linkedin-in"></i>
                                            </a>
                                          </li>
                                          <li className="list-inline-item">
                                            <a
                                              href={
                                                process.env.PUBLIC_URL + "/"
                                              }
                                            >
                                              <i className="fab fa-youtube"></i>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="instructor-desk">
                                      <p>
                                        Lorem ipsum dolor sit amet consectetur
                                        adipisicing elit. Quae perferendis
                                        delectus voluptate reiciendis animi nisi
                                        nemo tenetur sequi cum laudantium sit
                                        totam libero quasi ducimus accusantium
                                        numquam eaque.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="review" className="review-tab">
                          <Row>
                            <Col md="12">
                              <div className="review-comments">
                                <h5>Course Reviews</h5>
                                {courseDetails?.review?.map((data, i) => (
                                  <div className="comment-box d-flex" key={i}>
                                    <div className="comment-image">
                                      <img
                                        src={
                                          data?.authorImg !== null
                                            ? process.env.PUBLIC_URL +
                                              `/assets/images/` +
                                              data?.authorImg
                                            : ""
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="comment-content">
                                      <div className="content-title d-flex justify-content-between">
                                        <div className="comment-writer">
                                          <h6>{data?.reviewerName}</h6>
                                          <p>
                                            {/* {data.reviewDate} |{" "}
                                            {data.reviweTime} */}
                                            {/* {new Date(data?.createdAt)
                                              .toUTCString()
                                              .split(" ")
                                              .slice(0, 4)
                                              .join(" ")} */}
                                              {abc(data?.createdAt)}
                                          </p>
                                          <ul className="list-unstyled list-inline">
                                            <li className="list-inline-item">
                                              <i className="las la-star"></i>
                                            </li>
                                            <li className="list-inline-item">
                                              <i className="las la-star"></i>
                                            </li>
                                            <li className="list-inline-item">
                                              <i className="las la-star"></i>
                                            </li>
                                            <li className="list-inline-item">
                                              <i className="las la-star"></i>
                                            </li>
                                            <li className="list-inline-item">
                                              <i className="las la-star-half-alt"></i>
                                            </li>
                                            <li className="list-inline-item">
                                              {data?.reviewRating}
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="reply-btn">
                                          <button type="button">
                                            <i className="las la-reply-all"></i>{" "}
                                            Reply
                                          </button>
                                        </div>
                                      </div>
                                      <div className="comment-desc">
                                        <p>{data?.reviewComment}</p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div className="review-form">
                                {/* <h5>Submit Review</h5> */}
                                <ReviewForm courseId={courseId} />
                              </div>
                            </Col>
                          </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="project" className="overview-tab">
                          <div className="course-desc">
                            <h5>Project Description</h5>
                            <p>{courseDetails?.project?.projectDescription}</p>
                          </div>
                          {/*<div className="course-feature">
                                                        <h5>Project Feature</h5>
                                                        <p>{course.project.projectFeature}</p>
                                                        <ul className="list-unstyled">
                                                        {
                                                            course.project.featureList.map((data, i) => (
                                                            <li><i className="las la-arrow-right"></i> 
                                                            {data.feature}
                                                             </li>
                                                            ))
                                                        } 
                                                        </ul>
                                                    </div>*/}
                          <div className="course-learn">
                            <h5>Learning Outcome</h5>
                            <p>{courseDetails?.project?.learningOutcome}</p>
                            <ul className="list-unstyled">
                              {learningOutcome?.map((data, i) => (
                                <li>
                                  <i className="fa fa-check"></i>
                                  {data?.learning}
                                </li>
                              ))}
                            </ul>
                          </div>
                          {/* <div className="course-share">
                                                        <h5>Share This Course</h5>
                                                        <ul className="social list-unstyled list-inline">
                                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>
                                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>
                                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-youtube"></i></a></li>
                                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-dribbble"></i></a></li>
                                                        </ul>
                                                    </div>*/}
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="4" sm="12">
                <div className="single-details-sidbar">
                  <Row>
                    <Col md="12">
                      <div className="course-details-feature">
                        <h5 className="title">Course Details</h5>
                        <ul className="list-unstyled feature-list">
                          <li>
                            <i className="las la-calendar"></i> Start Date:{" "}
                            <span>
                              {/* {new Date(courseDetails?.course?.startingDate)} */}
                              {dateToStr[2] +
                                " " +
                                dateToStr[1] +
                                "," +
                                dateToStr[3]}
                            </span>
                          </li>
                          <li>
                            <i className="las la-clock"></i> Duration:{" "}
                            <span>{courseId && courseId == 3 ? courseDetails?.course?.duration+" "+"Months" :courseDetails?.course?.duration+" "+"Weeks"} </span>
                          </li>
                          <li>
                            <i className="las la-globe"></i> Language:{" "}
                            <span>{courseId && courseId == 4 ? "Hinglish" :courseDetails?.course?.language}</span>
                          </li>
                          <li>
                            <i className="las la-sort-amount-up"></i> Skill
                            Level:{" "}
                            <span>{courseDetails?.course?.skillLevel}</span>
                          </li>
                          <li>
                            <i className="las la-graduation-cap"></i> Subject:{" "}
                            <span>{courseId && courseId == 4 ? "ReactJS" :courseDetails?.course?.subject}</span>
                          </li>
                          <li>
                            <i className="las la-book"></i> Lectures:{" "}
                            <span>{courseDetails?.course?.lectures}</span>
                          </li>
                          <li>
                            <i className="las la-bookmark"></i> Enrolled:{" "}
                            <span>{courseDetails?.course?.enrolled}</span>
                          </li>
                          <li>
                            <i className="las la-certificate"></i>{" "}
                            Certification:
                            <span>
                              {courseDetails?.course?.certification
                                ? "YES"
                                : "NO"}
                            </span>
                          </li>
                        </ul>

                        <button
                          type="button"
                            onClick={handleSubmit}
                          className="enroll-btn"
                        >
                          Enroll Course
                        </button>
                      </div>
                    </Col>
                    <Col md="12">
                      <PopularCourse />
                    </Col>
                    <Col md="12">{/* <CourseTag />*/}</Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
}

export default CourseDetails;
