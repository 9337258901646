import React, { Component } from 'react';
import Datas from '../data/hero/hero-slider.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import { Styles } from "./styles/heroSlider.js";

class HeroSlider extends Component {
    render() {
        const settings = {
            slidesPerView: 1,
            loop: true,
            speed: 3000,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            watchSlidesVisibility: true,
            effect: 'fade',
            navigation: {
                nextEl: '.slider-button-next',
                prevEl: '.slider-button-prev'
            },
            renderPrevButton: () => (
                <div className="swiper-btn slider-button-prev"><i className="flaticon-arrow-left-th"></i></div>
            ),
            renderNextButton: () => (
                <div className="swiper-btn slider-button-next"><i className="flaticon-arrow-right-th"></i></div>
            )
        };

        return (
            <Styles>
                {/* Hero Slider */}
                <section className="hero-slider-area">
                    <Swiper >
                    {/* <Swiper {...settings}> */}
                        {
                            Datas.map((data, i) => (
                                <div className="slider-item" key={i}>
                                    <div className="image-container">
                                        <img src={process.env.PUBLIC_URL + `/assets/images/${data.backgroundImage}`} className="slider-image" alt={data.backgroundImage} />
                                    </div>
                                    <div className="slider-table">
                                        <div className={data.id==1 ? "slider-tablecell-v1": "slider-tablecell" }>
                                            <Container>
                                                <Row className="row-v1">
                                                    {data.id==1 &&
                                                    <Col md="12">
                                                        <div className={data.uniqClass}>
                                                            <div className="slider-title1">
                                                                <p>{data.title}</p>
                                                            </div>
                                                            <div className="slider-desc">
                                                                <h1>{data.desc}</h1>
                                                            </div>
                                                            {/* left Side data */}
                                                            
                                                        </div>
                                                        <div className={data.uniqClassLeft}>
                                                            {/* <div className="slider-title1">
                                                                <p>{data.title1}</p>
                                                            </div> */}
                                                            <div className="slider-desc1">
                                                                <h2>{data.desc1}</h2>
                                                            </div>
                                                            {data.id==1&&
                                                            <div className="slider-btn1">
                                                                {/* <Link className="slider-btn-live" to={process.env.PUBLIC_URL + "/live-class-registeration"}>Book Free Live Class</Link> */}
                                                                {/* <Link className="slider-btn-price">Price : ₹20000 <span style={{textDecoration:'line-through'}}>26000</span></Link> */}
                                                            </div>
                                                            }
                                                            </div>
                                                    </Col>}
                                                    {data.id==2&&
                                                    <Col md="12">
                                                        <div className={data.uniqClass}>
                                                            <div className="slider-title">
                                                                <p>{data.title}</p>
                                                            </div>
                                                            <div className="slider-desc">
                                                                <h1>{data.desc}</h1>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                     }
                                                </Row>
                                            </Container>
                                        </div>
                                        {data.id==1 && <div className="slider-tablecell-v2">
                                            <Container>
                                                <Row>
                                                    <Col md="12">
                                                        <div className={data.uniqClass}>
                                                            <div className="slider-desc">
                                                                <h1>{data.desc1}</h1>
                                                            </div>
                                                            {data.id==2&&
                                                            <div className="slider-btn">
                                                                <Link className="slider-btn1" to={process.env.PUBLIC_URL + `/${data.btnOneLink}`}>Register Now</Link>
                                                                <Link className="slider-btn2" to={process.env.PUBLIC_URL + `/${data.btnTwoLink}`}>Contact Us</Link>
                                                            </div>}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>}
                                    </div>
                                </div>
                            ))
                        }
                    </Swiper>
                </section>
            </Styles>
        )
    }
}

export default HeroSlider

