import React, { useEffect } from "react";
import Datas from "../data/free-course/free-course.json";
import { Container, Row, Col } from "react-bootstrap";
import Timer from "react-compound-timer";
import { Styles } from "./styles/freeCourse.js";
import * as authApi from "../api/AuthServices";

function FreeCourse() {
  const date1 = new Date("06/24/2022");
  const date2 = new Date();
  function getDifferenceInSeconds(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs;
  }
  useEffect(() => {
    const form = document.getElementById("form3");
    const name = document.getElementById("name3");

    const email = document.getElementById("email3");
    const phone = document.getElementById("phone3");

    form.addEventListener("submit", formSubmit);

    async function formSubmit(e) {
      e.preventDefault();
      let success = true;
      const nameValue = name.value.trim();
      const emailValue = email.value.trim();
      const phoneValue = phone.value.trim();

      if (nameValue === "") {
        setError(name, "Name can't be blank");
        success = false;
      } else {
        setSuccess(name);
      }

      if (emailValue === "") {
        setError(email, "Email can't be blank");
        success = false;
      } else if (!isEmail(emailValue)) {
        setError(email, "Not a valid email");
        success = false;
      } else {
        setSuccess(email);
      }

      if (phoneValue === "") {
        setError(phone, "Phone number can't be blank");
        success = false;
      } else if (isNaN(phoneValue)) {
        setError(phone, "Not a valid phone number");
        success = false;
      } else {
        setSuccess(phone);
      }
      if (success) {
        let userData = {};
        userData.studentName = nameValue;
        userData.phoneNumber = phoneValue;
        userData.email = emailValue;
        userData.qualification = "qualificationValue";
        userData.address = emailValue;
        try {
          let res = await authApi.registerUser(userData);
          // setRegistrationSuccess(successBox,"We have recieved your application. Someone from our team will get back to you.")
          alert(
            " We have recieved your application. Someone from our team will get back to you."
          );
        } catch (e) {
          alert(" user expe");
        }
      }
    }

    function setError(input, message) {
      const formControl = input.parentElement;
      const errorMsg = formControl.querySelector(".input-msg3");
      formControl.className = "form-control text-left error";
      errorMsg.innerText = message;
    }

    function setSuccess(input) {
      const formControl = input.parentElement;
      formControl.className = "form-control success";
    }

    function isEmail(email) {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
    }
  });

  return (
    <Styles>
      {/* Free Course */}
      <section className="free-course-area">
        <Container>
          <Row>
            <Col md="7">
              <div className="course-text">
                <h4>{Datas.secTitle}</h4>
                <p>{Datas.subTitle}</p>
              </div>
              <div className="countdown-timer">
                <Timer
                  initialTime={getDifferenceInSeconds(date2, date1)}
                  direction="backward"
                >
                  <p>
                    <span>
                      <Timer.Days />
                    </span>
                    Days
                  </p>
                  <p>
                    <span>
                      <Timer.Hours />
                    </span>
                    Hours
                  </p>
                  <p>
                    <span>
                      <Timer.Minutes />
                    </span>
                    Minutes
                  </p>
                  <p>
                    <span>
                      <Timer.Seconds />
                    </span>
                    Seconds
                  </p>
                </Timer>
              </div>
            </Col>
            <Col md="5">
              <div
                className="register-form text-center"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${Datas.formBackground})`,
                }}
              >
                <div className="form-box">
                  <h4 className="title">Sign Up Now</h4>
                  <p className="desc">Avail 30% discount</p>
                  <form id="form3" className="form">
                    <p className="form-control">
                      <input
                        type="text"
                        placeholder="Enter your Name"
                        id="name3"
                      />
                      <span className="input-msg3"></span>
                    </p>
                    <p className="form-control">
                      <input
                        type="email"
                        placeholder="Enter your Email"
                        id="email3"
                      />
                      <span className="input-msg3"></span>
                    </p>
                    <p className="form-control">
                      <input
                        type="text"
                        placeholder="Enter Phone NUmber"
                        id="phone3"
                      />
                      <span className="input-msg3"></span>
                    </p>
                    <button>Send Request</button>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}

export default FreeCourse;
