import React, { Component } from 'react';
import Datas from '../../data/faq/faq.json';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/data-policy.js';


import { Styles1 } from '../events/styles/eventDetails';

class DataPolicy extends Component {
    
     
      getDifferenceInSeconds( dateVar){
        const date1 = new Date(dateVar);
        const date2 = new Date(); 
        const diffInMs = Math.abs(date2 - date1);
        console.log("diffInMs    "+date1)
        return diffInMs ;
      }
     handleSubmit = (employee) => {
        
        this.props.history.push('/eventRegister');
      
    }
    render() {

        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper event-details-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Privacy Policy" />

                    {/* Event Details Area */}
                    <section className="event-details-area">
                        <Container>
                            <Row>
                                <Col lg="12" md="12" sm="12">
                                    <div className="event-details-content">
                                        <div className="event-details-overview">
                                            <p>
                                            This web site is owned and operated by SkillBhoomi Technologies Private Limited ("we", "our", or "us" or the "Company"). We understand and value your privacy. We want to make your experience online satisfying and safe.

                                            This privacy policy (the "Policy") governs information you provide to us or we learn from your use of this web site (the "Site") and constitutes a legal agreement between you, as the user of the Site, and the Company, as the owner of the Site. The Policy will also tell you how we may collect, use, and in some instances share this information. Our policies do not apply to third-party websites that are connected via links to our Site and may differ from other service offerings and you should carefully review the terms of service and this privacy notice before using these services.
                                            </p>
                                        </div>
                                        
                                        <div>

                                        </div>
                                        <div className="event-details-overview">
                                            <h5>Collected Information</h5>
                                            <p>
                                            In general, you can visit the Site without telling us who you are or revealing any personally identifiable information about yourself. By providing your Personal Information to us, you explicitly agree to our collection and use of such information as described in this Policy.
                                            </p>
                                            <p>
                                            If you choose to register, and are 13 years of age or older, we will collect and process such information from you, including but not limited to the below mentioned(“Personal Information”):
                                            </p>
                                            <ul className="list-unstyled">
                                                <li><i className="fa"></i> Information that you provide to us by filling in forms. This includes contact information such as name, email address, mailing address, phone number, financial information, if any, unique identifiers such as preferences information such as favourites lists, transaction history.</li>
                                                <li><i className="fa"></i> Information that you provide when you write directly to us (including by e-mail).</li>
                                                <li><i className="fa"></i> Information that you provide to us by writing on our blogs;</li>
                                                <li><i className="fa"></i> Information relating to logs is automatically reported by your browser each time you access our Site. 
                                                When you use the Site, our servers automatically record certain information that your web browser sends whenever you visit any website. 
                                                These server logs may include information such as your web request, Internet Protocol (IP) address, browser type, referring/ exit pages and URLs, number of clicks, domain names, landing pages, pages viewed, and other such information. We use this information, which does not identify users, to analyze trends and to gather demographic information about the user base as a whole. We do not link this automatically-collected data to personally identifiable information.</li>
                                                <li><i className="fa "></i> to recognize you when you return to our Site.</li>
                                                
                                                
                                            </ul>
                                        </div>
                                        <div className="event-details-overview">
                                            <h5>Usage of Collected Information</h5>
                                            <p>
                                            We use the information we collect for following purposes, including:
                                            </p>
                                           
                                            <ul className="list-unstyled">
                                                <li><i className="fa"></i> 
                                                To provide, personalise, maintain and improve our products and services, such as to enable support and other services, enable features to personalise your Skillbhoomi account;
                                                </li>
                                                <li><i className="fa"></i> 
                                                To carry out our obligations arising from any contracts entered into between you and us and to provide you with the relevant information and services;
                                                </li>
                                                <li><i className="fa"></i> 
                                                To administer and enhance the security of our  Platform and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;
                                                </li>
                                                <li><i className="fa"></i> 
                                                To provide you with information about services we consider similar to those that you are already using, or have enquired about, or may interest you such as promotional offers. If you are a registered user, we will contact you by electronic means (e-mail or push notifications or SMS or telephone) with information about these services;
                                                </li>
                                                <li><i className="fa "></i> 
                                                To generate and review reports and data about, and to conduct research on, our user base and Service usage patterns;
                                                </li>
                                                <li><i className="fa "></i> 
                                                To allow you to participate in interactive features of our Services, if any; or
                                                </li>
                                                <li><i className="fa "></i> 
                                                To measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you.
                                                </li>
                                                <li><i className="fa "></i> 
                                                To remind you of completing payments to obtain course access and remind you about class timings or class reschedule or class cancellations if any
                                                </li>
                                                
                                                
                                            </ul>
                                            <p>&nbsp;</p>
                                            We may combine the information that we receive from third parties with the information you give to us and information we collect about you for the purposes set out above. Further, we may anonymize and/or de-identify information collected from you through the Services or via other means, including via the use of third-party web analytic tools. As a result, our use and disclosure of aggregated and/or de-identified information is not restricted by this Policy, and it may be used and disclosed to others without limitation.
                                            
                                            We analyse the log files of our  Platform that may contain Internet Protocol (IP) addresses, browser type and language, Internet service provider (ISP), referring, app crashes, page viewed and exit websites and applications, operating system, date/time stamp, and clickstream data. This helps us to administer the website, to learn about user behavior on the site, to improve our product and services, and to gather demographic information about our user base as a whole.
                                           
                                        </div>
                                        <div className="event-details-overview">
                                            <h5>Children's Privacy</h5>
                                            <p>
                                            We are committed to protecting children's privacy online. This Site is intended for users above the age of 13. We do not knowingly collect payment related information from children.
                                            </p>
                                        </div>
                                        <div className="event-details-overview">
                                            <h5>Billing</h5>
                                            <p>
                                            If you use or provide services on the Site for which we implement a billing system for you, we will collect additional information from you so that we can process and collect billing information. For example, we may collect your mailing address to remit payments.
                                            </p>
                                        </div>
                                        <div className="event-details-overview">
                                            <h5>Cookies</h5>
                                            <p>
                                            The Site uses software tags called "Cookies" to identify customers when they visit our Site. Cookies are used to remember user preferences and maximize performance of our services. The information we collect with cookies is not sold, rented, or shared with any outside parties. Users who disable their Web browser's ability to accept cookies will be able to browse our Site but may not be able to successfully use our Service.

We use both session ID cookies and persistent cookies. A session ID cookie expires when you close your browser. A persistent cookie remains on your hard drive for an extended period of time. You can remove persistent cookies by following directions provided in your Internet browser's "help" file. Persistent cookies enable us to track and target the interests of our users to enhance the experience on our Site.

This privacy policy covers the use of cookies by our Site only and does not cover the use of cookies by any advertisers.
                                            </p>
                                        </div>
                                        <div className="event-details-overview">
                                            <h5>Third Party Cookies</h5>
                                            <p>
                                            We may from time to time engage third parties to track and analyze non-personally identifiable usage and volume statistical information from visitors to our Site to help us administer our Site and improve its quality. Such third parties may use cookies to help track visitor behaviour. Such cookies will not be used to associate individual Site visitors to any Personal Information. All data collected by such third parties on our behalf is used only to provide us with information on Site usage and is not shared with any other third parties.
                                            </p>
                                        </div>
                                        <div className="event-details-overview">
                                            <h5>Web Beacons</h5>
                                            <p>
                                            Web pages may contain an electronic file called a web beacon that allows a web site to count users who have visited that page or to access certain cookies. We may use web beacons in the following ways:
                                            </p>
                                            <ul className="list-unstyled">
                                                <li><i className="fa"></i> We use web beacons within the Site in order to count users and to recognize users by accessing our cookies.</li>
                                                <li><i className="fa"></i> Being able to access our cookies allows us to personalize your user experience.</li>
                                                <li><i className="fa"></i> In general, any file served as part of a web page, including an ad banner, can act as a web beacon.</li>
                                                
                                            </ul>
                                        </div>
                                        <div className="event-details-overview">
                                            <h5>Links to Third Party Sites</h5>
                                            <p>
                                            Our Site may, from time to time, contain links to and from the websites of our affiliated merchants, partner networks, affiliates and other third parties. The inclusion of a link does not imply any endorsement by us of the third party website, the website's provider, or the information on the third party website. If you follow a link to any of these websites, please note that these websites may be governed by their own privacy policies and we disclaim all responsibility or liability with respect to these policies or the websites. Please check these policies and the terms of the websites before you submit any information to these websites.
                                            </p>
                                        </div>
                                        <div className="event-details-overview">
                                            <h5>Log Files</h5>
                                            <p>
                                            We maintain log files of the traffic that visits our Site. We do not link any information gathered in these log files to Personal Information. Log files are used to manage traffic loads and information technology requirements for providing reliable service. Information collected includes IP addresses and browser types.
                                            </p>
                                        </div>
                                        <div className="event-details-overview">
                                            <h5>Security</h5>
                                            <p>
                                            All information you provide to us is stored on our secure servers. As a registered user with an account and a password, you are responsible for keeping your password confidential. We take commercially reasonable safeguards to protect and preserve the integrity and security of your Personal Information submitted to us, both during transmission and once we receive it against loss, theft, unauthorised access, disclosure, reproduction, use or amendment. No method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while we strive to use commercially reasonable means to protect your Personal Information, we assume no liability for any disclosure of information due to errors in transmission, unauthorised third party access or other acts of third parties, or acts or omissions beyond our reasonable control and you agree that you will not hold us responsible for any breach of security unless such breach has been caused as a direct result of our gross negligence or wilful default.. If we learn of a security systems breach we may attempt to notify you electronically so that you can take appropriate protective steps. By using this Site or providing personal information to us you agree that we can communicate with you electronically regarding security, privacy, and administrative issues relating to your use of this site. We may post a notice on our Site if a security breach occurs. We may also send an email to you at the email address you have provided to us in these circumstances. Depending on where you live, you may have a legal right to receive notice of a security breach in writing.
                                            </p>
                                        </div>
                                        <div className="event-details-overview">
                                            <h5>Retention of Information</h5>
                                            <p>
                                            We will keep Personal Information of our users for as long as they are registered subscribers or users of our products and services, and as permitted by law.
                                            </p>
                                        </div>
                                        <div className="event-details-overview">
                                            <h5>Sharing your personal information</h5>
                                            <p>
                                            If you have purchased our course, we may ask you for testimonial, we will display your testimonial on our website, photos and videos or in our social media channels such as Facebook, YouTube or Flickr. You should be aware that your publicly identifiable information could be used to send you promotional, unsolicited messages. We are not responsible for your personal information which you have chosen to display.
                                            If you don't want us to feature your pictures/testimonials on our website or on our social media channels, you can raise a support ticket from your Skillbhoomi Log in.
                                            </p>
                                        </div>
                                        <div className="event-details-overview">
                                            <h5>Personal Information Corrections</h5>
                                            <p>
                                            You can contact us if you notice that the information we are holding is incorrect or incomplete. Please raise a support ticket from your Skillbhoomi Log in.
                                            </p>
                                        </div>
                                        <div className="event-details-overview">
                                            <h5>Linking</h5>
                                            <p>
                                            This Site may contain links to other web sites. The Company cannot and does not control the privacy practices of any such sites. You should review the privacy policy on any web site where you may submit personal information before providing it to any web site.
                                            </p>
                                        </div>
                                        <div className="event-details-overview">
                                            <h5>Emails</h5>
                                            <p>
                                            If you choose to register for our products and services, we will send you certain promotional emails. Promotional emails advertise our products and services and/or the products and services of our Users and Affiliates. If you do not want to receive promotional emails from us, you may elect to opt-out of receiving promotional emails at any time by hitting the "unsubscribe" button at the bottom of any of our e-mails.
                                            </p>
                                        </div>
                                        <div className="event-details-overview">
                                            <h5>Legal Disclaimer</h5>
                                            <p>
                                            We reserve the right to disclose your Personal Information as required by law and when we believe that disclosure doing so in the Company's interest to protect its property or other legal rights or the rights or property of others.
                                            </p>
                                        </div>
                                        <div className="event-details-overview">
                                            <h5>Governing Law and Dispute Resolution</h5>
                                            <p>
                                            This Policy shall be governed by and construed in accordance with the laws of the Republic of India. Subject to arbitration, the courts at Bangalore shall have exclusive jurisdiction in relation to any disputes arising out of or in connection with this Policy.

                                            If any dispute arises between the Company and You in connection with or arising out of the validity, interpretation, implementation or alleged breach of any provision of the Policy, such dispute shall be referred to arbitration in accordance with the Indian Arbitration and Conciliation Act, 1996 for the time being in force. Arbitration shall be conducted by one (1) arbitrator mutually appointed by the Company and You. The seat of arbitration shall be Bangalore, Karnataka. The language of the arbitration proceedings and of all written decisions and correspondence relating to the arbitration shall be English.
                                            </p>
                                        </div>
                                        <div className="event-details-overview">
                                            <h5>International Users</h5>
                                            <p>
                                            This Site is operated in the India. If you are visiting from other regions with laws governing data collection and use that may differ from Indian law, please note that you are transferring your personal data to India, which does not have the same data protection laws and by providing your personal data you consent to:

                                            •The use of your personal data for the uses identified above in accordance with this Privacy Policy; and

                                            •The transfer of your personal data to the India as indicated above.

                                            The Company is also not making any representation that the content contained on the Product is appropriate or to be used or accessed outside of the Republic of India and your use or access of the Site from outside the Republic of India, is at your own risk and you are responsible for compliance with the laws of such jurisdiction.
                                            </p>
                                        </div>
                                        <div className="event-details-overview">
                                            <h5>Merger, Sale or Insolvency</h5>
                                            <p>
                                            If the Company should ever file for bankruptcy or have its assets sold to or merge with another entity, information the Company receives from you from this Site is a Company asset that may be transferred in connection with these types of corporate events.
                                            </p>
                                        </div>
                                        <div className="event-details-overview">
                                            <h5>Change in this Privacy Policy</h5>
                                            <p>
                                            We may occasionally update this Privacy Policy. When we do, we will also revise the "last updated" date on the Privacy Policy. For changes to this Privacy Policy that may be materially less restrictive on our use or disclosure of personal information you have provided to us, we will attempt to obtain your consent before implementing the change. We encourage you to periodically review this Privacy Policy to stay informed about how we are protecting the personal information we collect. Your continued use of this Site constitutes your agreement to this Privacy Policy and any updates.
                                            </p>
                                        </div>
                                        <div className="event-details-overview">
                                            <h5>Contact Us</h5>
                                            <p>
                                            If you have questions or concerns regarding this Policy, please contact us either by raising a support ticket from your Skillbhoomi Log in or by contacting us on our support phone number.
                                            </p>
                                        </div>

                                       
                                    </div>
                                </Col>

                                
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default DataPolicy